import { useEffect } from 'react';

import { useStores } from '@shared/hooks';
import { DraftProps, setDraftLS, isSimpleObject } from '@shared/utils';
import { FormStateType } from '@entities/index';

type NestedObject<T> = {
  [K in keyof T]: T[K] extends object ? NestedObject<T[K]> : T[K];
};

export const useVZRDraft = () => {
  const {
    MainStore: {
      initProductStore: { initState },
      productStore: { formState, hasPricesCalcErr, isFetching, price },
      draftStore: { checkDraft },
      applicationStore: { isResetFormState },
    },
  } = useStores();

  const clearedFormState: FormStateType = {
    ...formState,
    WhoAndHowPaperwork: {}, // удаляем перс данные страхуемых
    WhoIssuesPolicy: {}, // удаляем перс данные страхователя
    DocumentsList: {}, // очищаем согласие на условия
  };

  const getFilteredFieldArrayValues = <T extends object>(
    fieldValues?: NestedObject<T>[],
    errors?: NestedObject<T>
  ) => {
    return fieldValues?.filter((val) => {
      if (typeof val !== 'object' || val === null) {
        return true;
      }

      return getFilteredFieldValues(val, errors);
    });
  };

  const getFilteredFieldValues = <T extends object>(
    fieldValues?: NestedObject<T>,
    errors?: NestedObject<T>
  ) => {
    if (!errors || !Object.keys(errors).length) return fieldValues;

    const acc: Record<string, unknown> = {};

    for (const key in fieldValues) {
      if (key in errors) {
        const prop = fieldValues[key as keyof typeof fieldValues];

        if (Array.isArray(prop)) {
          const field = getFilteredFieldArrayValues(
            prop,
            errors[key] as object
          );

          acc[key] = field?.length ? field : undefined;
        } else if (isSimpleObject(prop)) {
          const field = getFilteredFieldValues(prop, errors[key] as object);
          if (field) acc[key] = field;
        }
        continue;
      }
      acc[key] = fieldValues[key];
    }

    return acc;
  };

  const getFilteredState = (state: FormStateType) => {
    const acc: Record<string, unknown> = {};

    for (const key in state) {
      const prop = key as keyof typeof state;

      acc[key] = getFilteredFieldValues(state[prop]);
      (acc[key] as unknown as any).isValid = undefined;
    }

    // TODO: в некоторых смарт компонентах использовали стор для хранения значений ошибок, это не должно попадать в черновик.
    // необходимо проверить приложение и убрать эти реализации, если остались
    acc.errors = undefined;

    return acc;
  };

  useEffect(() => {
    if (isFetching.current || hasPricesCalcErr || isResetFormState) return;

    const data: DraftProps = {
      dataObject: {
        formState: getFilteredState(clearedFormState),
      },
      selectedProduct: initState.code,
      promoCode:
        price.isApplyedPromo && price.isSuccessfulPromo ? price.promoCode : '',
    };

    if (!checkDraft) {
      setDraftLS(data);
    }
  }, [
    initState.code,
    hasPricesCalcErr,
    clearedFormState,
    isFetching.current,
    initState.code,
    formState,
    price.promoCode,
    price.isApplyedPromo,
    price.isSuccessfulPromo,
    isResetFormState,
  ]);

  return;
};
