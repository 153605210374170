import { ResponseError } from '@shared/types';

const matchCode = (
  input: string | undefined,
  code: ErrorCode | ErrorCode[]
) => {
  if (!input) return false;

  return Array.isArray(code)
    ? code.includes(input as ErrorCode)
    : input === code;
};
export const hasError = (
  error: ResponseError | null | undefined,
  code: ErrorCode | ErrorCode[],
  status = 400
) =>
  error?.response?.status === status &&
  matchCode(error.response?.data?.code, code);

export enum ErrorCode {
  BUSINESS_ERROR = 'BUSINESS_ERROR',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
  UNKNOWN_USER_OR_CODE = 'UNKNOWN_USER_OR_CODE',
  USER_DATA_ERROR = 'USER_DATA_ERROR',
}
