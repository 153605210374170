import { useEffect } from 'react';
import { useStores } from '@shared/hooks';
import { Endpoints, PRICES_MAX_VALUE } from '@shared/constants';
import { useGetPrices } from './use-get-prices';
import { getData } from './utils';
import { ErrorCode, hasError } from '@shared/utils';

export const useHandleGetPrice = () => {
  const {
    MainStore: {
      initProductStore: {
        insuranceLimitStart,
        initState,
        setInitFormState,
        initFormState,
      },
      errorStore: { displayGlobalErrorUrl, setErrorRetry },
      productStore: {
        ageRange,
        formState,
        currency,
        setPrice,
        setHasPricesCalcErr,
        setIsFetching,
        price: { promoCode, isApplyedPromo },
      },
    },
  } = useStores();

  const options = {
    traveling: Boolean(formState?.WhereAndHowLong?.traveling),
    effectiveSinceFranchise: insuranceLimitStart?.effectiveSinceFranchise,
    insurancePeriodOneTimeMin: insuranceLimitStart?.insurancePeriodOneTimeMin,
    insurancePeriodOneTimeMax: insuranceLimitStart?.insurancePeriodOneTimeMax,
    insurancePeriodShortMax: insuranceLimitStart?.insurancePeriodShortMax,
    ageRange: ageRange,
    hasError: displayGlobalErrorUrl?.includes(Endpoints.GET_PRICES),
  };

  const { isLoading, error, data, refetch, isFetching } = useGetPrices(
    getData(formState, initState, currency, isApplyedPromo ? promoCode : ''),
    options
  );

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (!isLoading && data) {
      // Сохранение начального состояния формы
      if (
        (!initFormState || Object.keys(initFormState).length === 0) &&
        Object.keys(formState).length !== 0
      ) {
        setInitFormState({ ...formState });
      }

      setErrorRetry(false);
      setPrice(data);
      setHasPricesCalcErr(false);
    }

    if (hasError(error, ErrorCode.BUSINESS_ERROR)) {
      setHasPricesCalcErr(true);
      setPrice({
        premiumAndDelta: String(PRICES_MAX_VALUE),
        premiumAndDeltaPromo: String(PRICES_MAX_VALUE),
      });
    }
  }, [isLoading, data, error, formState, initFormState]);

  return refetch;
};
