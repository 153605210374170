/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';

import { useTheme } from '@pulse-web-ui/theme';
import { Tooltip, TriggerDirection } from '@pulse-web-ui/tooltip';

import {
  StyledBaseBox,
  StyledBox,
  StyledBtn,
  StyledButton,
  StyledCheckIcon,
  StyledContainer,
  StyledHeader,
  StyledLabel,
  StyledOption,
  StyledPolicyLabel,
  StyledClose,
  StyledPrice,
  StyledSubLabel,
  StyledSubOptionLabel,
  StyledText,
  StyledTooltip,
  StyledHeaderBox,
  StyledTooltipText,
} from './options-list.styles';
import { currencies } from 'smart-components/who-and-how/components/coverage-sum/constants';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import type {
  OptionsType,
  RiskOptionsType,
  OptionsListProps,
} from './options-list.types';
import { RISK_VALUE } from './constants';
import { addTestAttribute, numFormat } from '@shared/utils';
import { EXCLUDE_OPTIONS, UnicodeEnum } from '@shared/constants';
import type { Option, RisksType } from '@shared/types';

import { i18nDefaultValues } from './i18n';

const CheckSmall = lazy(
  () => import('@pulse-web-ui/icons/lib/lazy/check-small')
);

// TODO: Проделать рефакторинг, убрать стор из смарт компонента
// смарт компоненты должны быть максимально изолированы от приложения,
// и все данные должны получать в пропсах, через то же value
const OptionsList = ({
  onClose,
  showButton = true,
  handleMoveOptionStep,
  optionsListTitle,
  basicProtectSubTitle,
  currency,
  risks,
  coverageSum,
  initStateRisks,
}: OptionsListProps) => {
  const [baseOptionsArray, setBaseOptionsArray] = useState<{
    base: OptionsType[];
    reimburse: OptionsType[];
  }>(Object);

  const [choosedRisksOptions, setChoosedRisksOptions] = useState<
    RiskOptionsType[]
  >([]);

  const theme: any = useTheme();

  useEffect(() => {
    if (risks) {
      const checkedOptions = risks
        ?.filter((item: any) => item.checked)
        .map((values: any) => ({
          ...values,
          coverageSum: values.sum || values.optionSum,
        }));

      setChoosedRisksOptions(checkedOptions);
    }
  }, [risks]);

  const baseOptions = useMemo(() => {
    const options =
      initStateRisks
        ?.find((item: RisksType) => item.code === RISK_VALUE)
        ?.options.filter((item: RisksType) => item.binding === true) || [];

    const base = options.filter(
      (item: Option) => !EXCLUDE_OPTIONS.includes(item.code)
    );
    const reimburse = options.filter((item: Option) =>
      EXCLUDE_OPTIONS.includes(item.code)
    );

    return {
      base,
      reimburse,
    };
  }, [initStateRisks]);

  useEffect(() => {
    if (initStateRisks) {
      setBaseOptionsArray(baseOptions);
    }
  }, [initStateRisks]);

  const { t } = useTranslation();

  const handleCancel = () => {
    onClose?.();
  };

  const handleMoveStep = () => {
    handleMoveOptionStep?.();
  };

  const renderElement = (
    el: any,
    labelText?: string | null,
    subLabelText?: string | null,
    headerText?: string | null,
    closeBtn?: boolean,
    part?: string,
    headerTooltip?: string
  ) => {
    return (
      <StyledBaseBox>
        {el?.length > 0 && (
          <>
            {labelText && (
              <StyledLabel isShowSubLabelText={!!subLabelText}>
                {labelText}
              </StyledLabel>
            )}
            {subLabelText && <StyledSubLabel>{subLabelText}</StyledSubLabel>}
          </>
        )}
        {headerText && (
          <StyledHeaderBox>
            <StyledSubOptionLabel>{headerText}</StyledSubOptionLabel>
            {headerTooltip && (
              <StyledTooltip>
                <Tooltip
                  tooltipText={
                    <StyledTooltipText>{headerTooltip}</StyledTooltipText>
                  }
                  trigger={TriggerDirection.HOVER}
                />
              </StyledTooltip>
            )}
          </StyledHeaderBox>
        )}
        {el?.map((item: any, index: number) => (
          <StyledOption
            key={index}
            {...addTestAttribute(`option-string-${part}-${index + 1}`)}
          >
            <StyledBox>
              <StyledCheckIcon>
                <Suspense>
                  <CheckSmall
                    width={24}
                    color={theme.colors.accent.success.normal}
                  />
                </Suspense>
              </StyledCheckIcon>
              <StyledText>{item.name}</StyledText>
              <StyledPrice>{`${t('COMMON:labels.to')} ${
                (item.coverageSum && numFormat(item.coverageSum)) ??
                numFormat(Number(coverageSum))
              }${UnicodeEnum.NBSP}${currencies[currency]}`}</StyledPrice>
              {item.description && (
                <StyledTooltip>
                  <Tooltip
                    tooltipText={item.description}
                    trigger={TriggerDirection.HOVER}
                  />
                </StyledTooltip>
              )}
            </StyledBox>
          </StyledOption>
        ))}
        {closeBtn && choosedRisksOptions.length > 0 && (
          <StyledBtn onClick={handleMoveStep} variant="setting">
            {t('SMART:OptionsList.labels.changeOptions', {
              defaultValue: i18nDefaultValues.OptionsList.labels.changeOptions,
            })}
          </StyledBtn>
        )}
      </StyledBaseBox>
    );
  };

  const { base, reimburse } = baseOptionsArray;

  return (
    <StyledContainer {...addTestAttribute('options-block')}>
      {(optionsListTitle || onClose) && (
        <StyledHeader>
          {optionsListTitle && (
            <StyledPolicyLabel>{optionsListTitle}</StyledPolicyLabel>
          )}
          {onClose && <StyledClose onClick={handleCancel} />}
        </StyledHeader>
      )}
      {!!choosedRisksOptions.length &&
        renderElement(
          choosedRisksOptions,
          t('SMART:OptionsList.labels.additionalProtect', {
            defaultValue:
              i18nDefaultValues.OptionsList.labels.additionalProtect,
          }),
          null,
          null,
          true,
          'choosedRisksOptions'
        )}

      {renderElement(
        base,
        t('SMART:OptionsList.labels.basicProtect', {
          defaultValue: i18nDefaultValues.OptionsList.labels.basicProtect,
        }),
        basicProtectSubTitle || null,
        t('SMART:OptionsList.labels.payment', {
          defaultValue: i18nDefaultValues.OptionsList.labels.payment,
        }),
        undefined,
        'base',
        t('SMART:OptionsList.hints.organizeAndPay', {
          defaultValue: i18nDefaultValues.OptionsList.hints.organizeAndPay,
        })
      )}

      {renderElement(
        reimburse,
        null,
        null,
        t('SMART:OptionsList.labels.compensateExpenses', {
          defaultValue: i18nDefaultValues.OptionsList.labels.compensateExpenses,
        }),
        undefined,
        'reimburse'
      )}

      {showButton && (
        <StyledButton
          onClick={handleCancel}
          {...addTestAttribute('modal-button-ok')}
        >
          {t('SMART:OptionsList.labels.understand', {
            defaultValue: i18nDefaultValues.OptionsList.labels.understand,
          })}
        </StyledButton>
      )}
    </StyledContainer>
  );
};

export default OptionsList;
