import { useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@pulse-web-ui/select';

import { currencies } from './constants';
import { i18nDefaultValues } from '../../i18n';
import { type Props } from './coverage-sum.type';

export const CoverageSum: FC<Props> = ({
  onChange,
  value,
  coverageSum,
  currency,
}) => {
  const { t } = useTranslation();

  const options = coverageSum?.map((item) => ({
    value: `${item}`,
    label: `${item.toLocaleString('ru-RU')} ${currencies[currency]}`,
  }));

  useEffect(() => {
    if (!value && coverageSum) {
      onChange(String(options?.[0].value), true);
    }
  }, [value, coverageSum]);

  return (
    <Select
      onChange={onChange}
      value={value || String(options?.[0].value) || ''}
      options={options}
      placeholder={t('SMART:WhoAndHow.labels.sumInsured', {
        defaultValue: i18nDefaultValues.WhoAndHow.labels.sumInsured,
      })}
      label={t('SMART:WhoAndHow.labels.sumInsured', {
        defaultValue: i18nDefaultValues.WhoAndHow.labels.sumInsured,
      })}
      isLoading={false}
      disabled={false}
      testId="travel-coverage-sum"
    />
  );
};
