import type { ReactNode } from 'react';
import {
  ItemCardAction,
  ItemCardContent,
  ItemCardHeader,
  ItemCardWrapper,
} from './footer-smart-component.styles';
import { addTestAttribute } from '@shared/utils';

type ItemCardType = {
  name: string;
  value: ReactNode;
  action?: () => void;
  labelTest?: string;
};

export const ItemCard = ({
  name,
  value,
  action,
  labelTest = 'item-card',
}: ItemCardType) => (
  <ItemCardWrapper {...addTestAttribute(labelTest)}>
    <ItemCardHeader>{name}</ItemCardHeader>
    <ItemCardContent>
      {action ? (
        <ItemCardAction onClick={action} {...addTestAttribute('card-action')}>
          {value}
        </ItemCardAction>
      ) : (
        value
      )}
    </ItemCardContent>
  </ItemCardWrapper>
);
