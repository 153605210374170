/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';

/*
 * Этот хук помогает вызывать setValue при изменении значения во внешней форме.
 * Сравнение идёт по поверхности с первой глубиной вложенности объекта.
 * Для сложных случаев сравнения есть возможность расширить текущий компаратор или переопределить его.
 */
export const useUpdateLocalForm = <T>(
  getValues: () => T,
  newValue: T | undefined,
  setValue: (k: keyof T, v: any) => void,
  scheme: ObjectSchema<any>,
  comparator?: (a: any, b: any) => boolean
) => {
  const [fields, setFields] = useState<(keyof T)[]>([]);

  useEffect(() => {
    setFields(Object.keys(scheme.describe().fields) as (keyof T)[]);
  }, [scheme]);

  useEffect(() => {
    if (newValue) {
      fields.forEach((field) => {
        if (!isEqual(getValues()[field], newValue[field], comparator)) {
          setValue(field, newValue[field]);
        }
      });
    }
  }, [newValue, fields, comparator]);
};

const isEqual = (
  a: any,
  b: any,
  comparator?: (a: any, b: any) => boolean
): boolean => {
  if (comparator) return comparator(a, b);

  if (typeof a == 'object') {
    switch (a?.constructor) {
      case Date:
        return a.getTime() == b.getTime();

      default:
        return a == b;
    }
  }

  return a == b;
};
