import styled, { css } from 'styled-components';

export const StyledContainer = styled.div<{ isMobileApp: boolean }>`
  margin-bottom: 8px;
  ${({ isMobileApp }) =>
    isMobileApp &&
    css`
      margin-top: 16px;
    `}
`;
