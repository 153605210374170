/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export const useFontLink = (fontLink: string, theme: any) => {
  useEffect(() => {
    if (theme.common['font-url'] && fontLink) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${fontLink}/${theme.common['font-url']}/font.css`;
      document.head.appendChild(link);
    } else if (theme.common['font-family']) {
      const headerStyles = [...document.styleSheets].filter(
        (item) =>
          item.href ===
            `https://fonts.googleapis.com/css?family=${encodeURI(
              theme.common['font-family']
            )}` ||
          item.href ===
            `${window.envUrls.STORAGE_URL}/common-assets/rgs/fonts/${theme.common['font-family'].replaceAll(/\s/g, '')}/font.css`
      );

      if (headerStyles.length === 0) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://fonts.googleapis.com/css?family=${theme.common['font-family']}`;
        document.head.appendChild(link);
      }
    }
  }, [fontLink, theme.common['font-url'], theme.common['font-family']]);
};
