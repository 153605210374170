import { StorageItem } from '@shared/types';

export const cleartDraft = () => {
  const selectedProduct = localStorage.getItem(StorageItem.SELECTED_PRODUCT);

  if (selectedProduct !== null) {
    localStorage.removeItem(selectedProduct);
  }

  return;
};
