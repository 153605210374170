import { useRequest, useStores } from '@shared/hooks';
import type { GetActivitiesResponse } from '@shared/types';
import { Endpoints } from '@shared/constants';
import { useEffect } from 'react';

export const useHandleSportList = () => {
  const {
    MainStore: {
      initProductStore: { initState },
    },
  } = useStores();

  const {
    isLoading: isLoadingSportList,
    res: resSportList,
    refetch: refetchSportList,
  } = useRequest<GetActivitiesResponse>(
    'get-sport-activity',
    'post',
    Endpoints.GET_SPORT_ACTIVITY,
    {
      baseParameters: {
        productVersion: initState.code,
      },
    },
    [initState.code],
    true
  );

  useEffect(() => {
    if (initState?.code && !initState?.activities) {
      refetchSportList();
    }
  }, [initState?.code, initState?.activities]);

  return { isLoadingSportList, resSportList, refetchSportList };
};
