import { getFormattedDate, scrollToIframe } from '@shared/utils';
import { useStores } from '@shared/hooks';
import { useEffect } from 'react';
import { UpdateProfileResponse } from '@shared/queries/application/update-profile/use-update-profile.types';
import { FieldValues } from 'react-hook-form';

export const useHandleNextStep = (updateProfileRefetch: () => Promise<any>) => {
  const {
    MainStore: {
      applicationStore: {
        activeStep,
        setActiveStep,
        wantNextStep,
        setWantNextStep,
        setPaymenStep,
        flowConfig: { otpStep, maxSteps },
      },
      authStore: { setUseOtp, profile, accessToken, setDisableForm },
      productStore: { formState, formString },
    },
  } = useStores();

  useEffect(() => {
    if (wantNextStep && activeStep < (maxSteps || 0)) {
      scrollToIframe();
      if (
        activeStep === otpStep &&
        wantNextStep &&
        shouldUpdateProfile(formState?.WhoIssuesPolicy, profile?.profile)
      ) {
        setDisableForm(true);
        updateProfileRefetch().then((res) => {
          setDisableForm(false);
          if (res?.data) {
            setActiveStep(activeStep + 1);
          }
        });
      } else {
        setUseOtp(false);
        setActiveStep(activeStep + 1);
      }
    }

    // автоинициализация init-order по отметке чекбокса о согласии
    // с условиями обработки данных в documents list
    if (
      formState?.DocumentsList?.acceptRequirements &&
      activeStep === maxSteps
    ) {
      setPaymenStep(true);
    }

    setWantNextStep(false);
  }, [wantNextStep, activeStep, accessToken, profile, formState, formString]);
};

const shouldUpdateProfile = (
  WhoIssuesPolicy?: FieldValues,
  profile?: UpdateProfileResponse['profile']
): boolean => {
  if (!WhoIssuesPolicy || !profile) return false;

  return (
    compareChanges(WhoIssuesPolicy.email, profile.email) ||
    compareChanges(
      getFormattedDate(WhoIssuesPolicy.birthDate),
      profile.birthDate
    ) ||
    compareChanges(WhoIssuesPolicy.name, profile.firstName) ||
    compareChanges(WhoIssuesPolicy.secondName, profile.middleName) ||
    compareChanges(WhoIssuesPolicy.surname, profile.lastName)
  );
};

const compareChanges = (fieldStore?: string, fieldService?: string): boolean =>
  !!fieldStore && String(fieldStore).localeCompare(String(fieldService)) !== 0;
