/* eslint-disable indent */
import {
  importCommonComponents,
  importSmartComponents,
} from '@entities/import-smart-components';
import type {
  BaseComponent,
  FormBuilderStructure,
  SmartComponent,
} from '@shared/types';
import type { ConfigSmartComponent, ProductStep, StepConfig } from 'mock';
import type { HeaderStep } from 'smart-components';

export const initState: FormBuilderStructure = {
  footer: [],
  body: [],
  submit: [],
  header: [],
};

export const getCommonComponents = (
  array: ConfigSmartComponent[]
): BaseComponent<any>[] =>
  array
    .filter((component) => !!importCommonComponents[component.name])
    .map((component) => {
      const { value, ...options } = component?.additionalVariables || {};

      return {
        component: importCommonComponents[component.name],
        props: {
          name: component.name,
          value: {
            ...value,
          },
          options,
        },
      };
    });

type FlowStoreConfig = {
  components: SmartComponent<any>[];
  header: HeaderStep;
  steps: StepConfig;
};

export const getSmartComponents = (
  { components, name, icon, additionalVariables }: ProductStep,
  flowIndex: number
): FlowStoreConfig => ({
  steps: {
    step: flowIndex + 1,
    value: { ...additionalVariables },
  },
  header: {
    icon,
    name,
  },
  components: components
    .filter((component) => !!importSmartComponents[component.name])
    .map((component) => {
      const { value, ...options } = component?.additionalVariables || {};

      return {
        component: importSmartComponents[component.name],
        step: flowIndex + 1,
        props: {
          name: component.name,
          value: {
            ...value,
          },
          options,
        },
      };
    }),
});

// TODO: Upgrade it to theme or preset merge
export const mergeAgentHeaderConfig = (
  headerConfig: HeaderStep[],
  agentLogin?: string
) => {
  if (!agentLogin) return headerConfig;

  const agentPaymentStep: HeaderStep = {
    name: 'formalization',
    icon: 'DocumentFull',
  };

  const agentClientStep: HeaderStep = {
    name: 'clients',
    icon: 'Neighbors',
  };

  return headerConfig.map((step) => {
    switch (step.name) {
      case 'payment':
        return agentPaymentStep;
      case 'formalization':
        return agentClientStep;
      default:
        return step;
    }
  });
};
