import { useEffect, useState } from 'react';
import { useStores } from '@shared/hooks';
import { prepareRisksOptionsList } from '@features/prepare-risks-options-list';
import {
  CODE_DESCRIPTION,
  INSURANCE_SUM_DEFAULT,
  RISK_VALUE,
  EXCLUDE_OPTIONS,
} from '@shared/constants';
import { useGetRisks } from './get-risks';
import type { FormBuilderUpdateHandler } from '@features/form-builder';

export const useHandleGetRisks = (
  updateFormValue: FormBuilderUpdateHandler
) => {
  const {
    MainStore: {
      initProductStore: { setInitState, initState },
      productStore: { setFullRisksOptionsLength, formState },
    },
  } = useStores();

  const [previousCoverageSum, setPreviousCoverageSum] = useState<string>(
    String(INSURANCE_SUM_DEFAULT)
  );

  const { res, isLoading, refetch } = useGetRisks({
    baseParameters: {
      productVersion: initState?.code,
    },
    referenceProperty: {
      codeDescription: CODE_DESCRIPTION,
      insuranceSum: String(
        formState?.WhoAndHow?.coverageSum || INSURANCE_SUM_DEFAULT
      ),
    },
  });

  useEffect(() => {
    if (initState?.code && !initState?.risks) {
      refetch();
    }
  }, [initState?.code, initState?.risks]);

  useEffect(() => {
    if (
      previousCoverageSum != formState?.WhoAndHow?.coverageSum &&
      initState?.code
    ) {
      setPreviousCoverageSum(formState?.WhoAndHow?.coverageSum);
      refetch();
    }
  }, [formState?.WhoAndHow?.coverageSum]);

  useEffect(() => {
    if (!isLoading && res && initState.territories) {
      setInitState({ ...initState, ...res });
      const foundRisk = res?.risks.find((item: { code: string }) => {
        return item.code === RISK_VALUE;
      });
      const includedOptions = foundRisk?.options?.filter(
        (item: { code: string }) => !EXCLUDE_OPTIONS.includes(item.code)
      );

      const optionLength = Array.isArray(includedOptions)
        ? includedOptions.length
        : 0;
      setFullRisksOptionsLength(optionLength);

      // Подготовка данных для рисков и опций (2 шаг)
      prepareRisksOptionsList(
        res,
        updateFormValue,
        formState?.WantImprovePolicy?.fullOptionsRisks || []
      );
    }
  }, [isLoading, res, initState.territories]);

  return refetch;
};
