import { useEffect } from 'react';
import { useStores } from '@shared/hooks';

export const useHandleFooter = () => {
  const {
    MainStore: {
      applicationStore: { setIsFooterShowing },
      productStore: { isPolicyBanned },
    },
  } = useStores();

  useEffect(() => {
    if (isPolicyBanned) return setIsFooterShowing(false);

    return () => setIsFooterShowing(true);
  }, [isPolicyBanned]);
};
