import { PaymentLayout } from '@shared/layouts/payment-layout';
import { Trans, useTranslation } from 'react-i18next';

import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { scrollToIframe } from '@shared/utils';
import { useEffect } from 'react';
import { policyDone } from 'pages/success-pay/constants';

export const InProgressPay = observer(() => {
  const {
    MainStore: {
      applicationStore: { deviceType, phoneFromMobileApp },
    },
  } = useStores();
  const { t } = useTranslation();

  useEffect(() => {
    scrollToIframe();
  }, []);

  return (
    <PaymentLayout
      policyLabel={t('COMMON:pay.payInProgressLabel')}
      isMobileApp={Boolean(phoneFromMobileApp && deviceType)}
      policyText={<Trans>{t('COMMON:pay.payInProgressText')}</Trans>}
      items={policyDone}
    />
  );
});
