import { WhoIssuesPolicyDataType } from '@features/auth-box/auth-box.type';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Endpoints, QUERY_KEYS } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { WhoIssuesPolicyValues } from 'smart-components';
import { UpdateProfileResponse } from '@shared/queries/application/update-profile/use-update-profile.types';

export const useHandleGetProfile = (
  whoIssuesPolicyData: WhoIssuesPolicyDataType,
  updateFormValue: (fieldName: string, value: any) => void,
  setFormValueError: (fieldName: string, error: any) => void,
  refetchRegistration: () => void,
  removeConfirmAuthentication: () => void
) => {
  const {
    MainStore: {
      applicationStore: { deviceType, phoneFromMobileApp },
      errorStore: { setErrorRetry },
      initProductStore: { setInitFormState, initFormState },
      authStore: {
        handleAuth,
        handleOtp,
        handleProfile,
        setAuthTokens,
        accessToken,
        authTokens,
        setDisableForm,
        setHasAuthorized,
        hasAuthorized,
        setRefetchAuthentication,
        handleUpdateDisableProfileState,
        setUseOtp,
        profile: storeProfile,
      },
      productStore: { isPolicyBanned },
    },
  } = useStores();
  const { t } = useTranslation();

  const { isLoading, error, res, refetch, remove } =
    useRequest<UpdateProfileResponse>(
      QUERY_KEYS.getProfile,
      'post',
      Endpoints.GET_PROFILE,
      {},
      [],
      true,
      accessToken
    );

  useEffect(() => {
    if (!isLoading && res) {
      setErrorRetry(false);

      const { profile, lockedFields } = res;

      const newValue: Partial<WhoIssuesPolicyValues> = {
        name: profile?.firstName,
        surname: profile?.lastName,
        secondName: profile?.middleName,
        phone: profile?.phone.slice(2),
        birthDate: profile?.birthDate
          ? new Date(profile?.birthDate)
          : undefined,
        email: profile?.email || '',
        forMe: whoIssuesPolicyData?.forMe || profileDataIsFulfilled(profile),
        hasAuthorized: hasAuthorized,
        isValid: !!profile?.email && !!profile.birthDate,
      };

      window.parent.postMessage(
        { csrfToken: authTokens?.authorization.csrfToken },
        '*'
      );
      handleProfile(res);
      handleUpdateDisableProfileState({
        name: !!profile?.firstName,
        surname: !!profile?.lastName,
        secondName: lockedFields?.middleName || !!profile?.middleName,
        birthDate: !!profile?.birthDate,
      });
      updateFormValue('WhoIssuesPolicy', newValue);

      // обновление начального состояния формы для повторного прохождения
      // после успешной оплаты без потери авторизации.

      setInitFormState({
        ...initFormState,
        WhoIssuesPolicy: newValue,
      });
      setDisableForm(false);
    }

    if (
      !isLoading &&
      hasAuthorized &&
      error &&
      error?.response?.status === 403
    ) {
      setDisableForm(true);
      localStorage.removeItem('csrfToken');

      if (whoIssuesPolicyData?.client) {
        setFormValueError(
          'WhoIssuesPolicy.phone',
          t('COMMON:errors.numberNotRegistered')
        );
        setHasAuthorized(false);
        setAuthTokens(undefined);
        removeConfirmAuthentication();
        setUseOtp(true);
        handleOtp('', false);
        handleAuth('');
        setRefetchAuthentication(true);

        const shouldUpdateFormState =
          deviceType &&
          phoneFromMobileApp &&
          whoIssuesPolicyData.showSwitcher === false;

        if (shouldUpdateFormState) {
          updateFormValue('WhoIssuesPolicy', {
            ...whoIssuesPolicyData,
            showSwitcher: true,
            client: false,
          });
        }
      } else {
        refetchRegistration();
      }
    }
  }, [isLoading, error, res, hasAuthorized]);

  useEffect(() => {
    const hasAccessToken = Boolean(accessToken);
    setHasAuthorized(hasAccessToken);

    if (accessToken && !storeProfile) {
      setDisableForm(true);
      refetch();
    }
  }, [accessToken, storeProfile]);

  useEffect(() => {
    if (isPolicyBanned) remove();
  }, [isPolicyBanned]);

  return {
    isLoadingGetProfile: isLoading,
    errorGetProfile: error,
    resGetProfile: res,
    refetchGetProfile: refetch,
  };
};

// Оформляю полис на себя включен, если у профайла заполнены все поля для заполнения путешественника.
const profileDataIsFulfilled = (
  profile: UpdateProfileResponse['profile']
): boolean => !!profile.firstName && !!profile.lastName && !!profile.birthDate;
