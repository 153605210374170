import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { assignRef, useCallbackRef } from 'use-callback-ref';
import { useQueryClient } from 'react-query';

import { useRequest, useStores } from '@shared/hooks';
import { Endpoints, QUERY_KEYS } from '@shared/constants';
import { OPERATION_ID } from '@shared/operations';
import { ErrorCode } from '@shared/utils';
import type {
  CompletePaymentRequest,
  CompletePaymentResponse,
} from './complete-payment.types';
import type { VZRPaymentStage } from '@shared/types';
import { PublicRoutes } from '@shared/routes';

export const useCompletePayment = (data: CompletePaymentRequest) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: { accessToken },
      productStore: { formState },
      applicationStore: { updateFormValue },
      initProductStore: { initOrder },
    },
  } = useStores();
  const [tick, setTick] = useState(0);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const completePaymentData = { ...data };

  const { res, isLoading, refetch } = useRequest<CompletePaymentResponse>(
    QUERY_KEYS.completePayment,
    'post',
    Endpoints.COMPLETE_PAYMENT,
    completePaymentData,
    [completePaymentData, accessToken],
    true,
    accessToken
  );

  const {
    isLoading: isLoadingPaymentStage,
    res: resPaymentStage,
    refetch: refetchPaymentStage,
    error: errorPaymentStage,
  } = useRequest<VZRPaymentStage>(
    `payment-stage-polling-${tick}`,
    'get',
    `${Endpoints.PAYMENT_STAGE}/${completePaymentData.id}`,
    {
      operationId: completePaymentData.id,
    },
    [completePaymentData.id, accessToken, tick, formState.B2P?.showInitOrder],
    completePaymentData.id && formState.B2P?.showInitOrder ? false : true,
    accessToken
  );

  const onRefPaymentSuccessFailUpdate = useCallback((newValue: any) => {
    if (newValue && newValue !== null) {
      const parsedValue = JSON.parse(newValue);
      if (parsedValue === false) {
        updateFormValue('DocumentsList', {
          ...formState.DocumentsList,
          ...{ acceptRequirements: false },
        });
        updateFormValue('B2P', {
          initOrder: undefined,
          showInitOrder: false,
          isValid: true,
        });
        navigate(PublicRoutes.FAILED_PAY);
      }
    }
  }, []);

  const onRefOperationIdUpdate = useCallback(
    (newValue: any) => {
      if (newValue && newValue !== null) {
        completePaymentData.operationId = newValue;

        if (
          !!completePaymentData?.id &&
          resPaymentStage?.status === 'COMPLETE'
        ) {
          refetch().then((res) => {
            const isCompleted =
              res.data?.data?.operationStatus === OPERATION_ID.COMPLETE;

            isCompleted
              ? navigate(PublicRoutes.SUCCESS_PAY)
              : navigate(PublicRoutes.IN_PROGRESS_PAY);
          });
        }
      }
    },
    [completePaymentData, resPaymentStage]
  );

  const refPaymentDeclineId = useCallbackRef<null | string>(
    null,
    onRefPaymentSuccessFailUpdate
  );

  const refSOperationId = useCallbackRef<null | string>(
    null,
    onRefOperationIdUpdate
  );

  useEffect(() => {
    if (
      completePaymentData.id &&
      String(completePaymentData.id).length > 0 &&
      formState.B2P?.showInitOrder
    ) {
      refetchPaymentStage();
    }
  }, [completePaymentData.id, tick, formState.B2P?.showInitOrder]);

  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (!isLoadingPaymentStage && (resPaymentStage || errorPaymentStage)) {
      if (
        !timerId &&
        (resPaymentStage?.status === 'PROCESSING' || errorPaymentStage)
      ) {
        const tickTimerId = setInterval(() => {
          setTick((prevTick) => prevTick + 1);
          queryClient.removeQueries(`payment-stage-polling-${tick}`); // сброс кэша всех запросов для правильного перезапуска приложения
        }, 2000);

        setTimerId(tickTimerId);

        setTimeout(() => {
          clearInterval(tickTimerId);
          setTimerId(tickTimerId);
        }, initOrder.timeout * 1000);
      }

      if (
        resPaymentStage?.status === 'COMPLETE' &&
        refSOperationId.current !== resPaymentStage.operationId
      ) {
        assignRef(refSOperationId, String(resPaymentStage.operationId));
      }
      if (resPaymentStage?.status === 'FAILED') {
        assignRef(refPaymentDeclineId, 'false');
      }
    } else if (!isLoadingPaymentStage && !resPaymentStage) {
      clearInterval(timerId);
      setTimerId(undefined);
    }

    if (initOrder?.code === ErrorCode.BUSINESS_ERROR) {
      navigate(PublicRoutes.SUCCESS_PAY);
    }
  }, [isLoadingPaymentStage, resPaymentStage, initOrder, errorPaymentStage]);

  useEffect(() => {
    if (!isLoading && res) {
      setErrorRetry(false);
      assignRef(refSOperationId, undefined);
    }
  }, [isLoading, res]);

  return refetch;
};
