/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormBuilderStructure } from '@shared/types';

import { getDraft } from '@shared/utils';

export type formValue = {
  [key: string]: any;
};

export const getDefaultValues = (
  components: FormBuilderStructure,
  effectiveSinceFranchise: string
): formValue => {
  const bodyValues = components.body.reduce((values, item) => {
    return {
      ...values,
      [item.props.name]: {
        ...item.props.value,
      },
    };
  }, {});

  let draft;
  const { resData } = getDraft(effectiveSinceFranchise);
  if (resData?.state) {
    draft = { ...resData.state.formState };
  }

  return {
    ...bodyValues,
    ...draft,
  };
};
