/* eslint-disable indent */
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { addTestAttribute } from '@shared/utils';

import { i18nDefaultValues } from '../../i18n';

import { Submit, SubmitLink } from './submit-button.styles';
import type { Props } from './submit-button.types';

export const SubmitButton: FC<Props> = ({
  onClick,
  buttonText,
  currentStep,
  disabled,
  b2pRoute,
}) => {
  const { t } = useTranslation();

  if (b2pRoute) {
    return (
      <SubmitLink
        disabled={disabled}
        isSecondStep={currentStep === 1}
        onClick={onClick}
        data-test="Submit"
        route={b2pRoute}
        target="_blank"
      >
        {buttonText
          ? buttonText
          : t('SMART:Submit.labels.continue', {
              defaultValue: i18nDefaultValues.Submit.labels.continue,
            })}
      </SubmitLink>
    );
  }
  return (
    <Submit
      disabled={disabled}
      isSecondStep={currentStep === 1}
      onClick={onClick}
      {...addTestAttribute('travel-submit')}
    >
      {buttonText
        ? buttonText
        : t('SMART:Submit.labels.continue', {
            defaultValue: i18nDefaultValues.Submit.labels.continue,
          })}
    </Submit>
  );
};
