/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Datepicker, ReactDatePicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Select } from '@pulse-web-ui/select';

import { CalendarItem, CalendarStyle } from './calendar.style';

import type { FC } from 'react';
import type { Props } from './calendar.type';

import {
  getMessageErrorForEndDate,
  getMessageErrorForStartDate,
} from './utils';
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { addTestAttribute } from '@shared/utils';

import { i18nDefaultValues } from '../../i18n';

export const Calendar: FC<Props> = ({
  value,
  onChange,
  error,
  policy,
  startDate,
  minDateTravel,
  endDate,
  insurancePeriodAnnual,
}) => {
  const { t } = useTranslation();
  const [isOpenEndDateCalendar, setIsOpenEndDateCalendar] = useState(false);
  const endDateCalendarRef = useRef<
    ReactDatePicker & { setOpen: (open: boolean) => void }
  >(null);

  const toggleOpenEndDateCalendar = () => {
    setIsOpenEndDateCalendar((prev) => !prev);
  };

  const handleSendDatesAnalytics = (selectedDate?: Date | null) => {
    if (selectedDate) {
      sendAnalyticEvent(analyticEvents.travelDatesSelected);
    }
  };

  const handleStartDate = (date: Date) => {
    let end = value?.end;
    const shouldUpdateEnd = !end || end < date;

    if (shouldUpdateEnd) {
      end = date;
    }

    if (policy) {
      const endDateForPolicy = new Date(date);
      endDateForPolicy.setFullYear(endDateForPolicy.getFullYear() + 1);
      endDateForPolicy.setDate(endDateForPolicy.getDate() - 1);
      end = endDateForPolicy;
    }

    onChange({
      ...value,
      start: date,
      end,
    });

    if (endDateCalendarRef?.current?.setOpen && shouldUpdateEnd) {
      endDateCalendarRef.current.setOpen(true);
      endDateCalendarRef.current.setFocus();
    }

    handleSendDatesAnalytics(end);
  };

  const handleDuration = (duration: string) => {
    onChange({
      ...value,
      duration: duration,
    });
    handleSendDatesAnalytics(value?.start);
  };

  const handleEndDate = (date: Date) => {
    onChange({
      ...value,
      end: date,
      ...(!value?.start ? { start: minDateTravel } : {}),
    });
    handleSendDatesAnalytics(minDateTravel);
  };

  const minDateForEndTravel = useMemo(() => {
    let minDate = new Date(startDate);

    if (value?.start) {
      minDate = new Date(value.start);
    }

    if (minDate.getTime() < minDateTravel.getTime())
      return new Date(minDateTravel.getTime());
    return minDate;
  }, [value?.start, startDate, minDateTravel]);

  const options = useMemo(
    () =>
      insurancePeriodAnnual.map((period) => ({
        value: String(period),
        label: String(period),
      })),
    [insurancePeriodAnnual]
  );

  return (
    <CalendarStyle>
      <CalendarItem>
        <HelperText
          noMargin
          status={error ? 'error' : 'default'}
          message={getMessageErrorForStartDate(error)}
        >
          <Datepicker
            disabledKeyboardNavigation
            minDate={minDateTravel}
            maxDate={new Date(endDate)}
            selected={value?.start}
            onChange={handleStartDate}
            label={
              policy
                ? t('SMART:WhereAndHowLong.labels.startPolicy', {
                    defaultValue:
                      i18nDefaultValues.WhereAndHowLong.labels.startPolicy,
                  })
                : t('SMART:WhereAndHowLong.labels.startTravel', {
                    defaultValue:
                      i18nDefaultValues.WhereAndHowLong.labels.startTravel,
                  })
            }
            error={!!getMessageErrorForStartDate(error)}
            {...addTestAttribute('travel-start-date')}
          />
        </HelperText>
      </CalendarItem>
      {policy ? (
        <CalendarItem>
          <HelperText
            noMargin
            status={error ? 'error' : 'default'}
            message={error?.duration?.message}
          >
            <Select
              onChange={handleDuration}
              value={value?.duration || ''}
              options={options}
              label={t('SMART:WhereAndHowLong.labels.dateTravel', {
                defaultValue:
                  i18nDefaultValues.WhereAndHowLong.labels.dateTravel,
              })}
              status={error?.duration?.message ? 'error' : undefined}
              placeholder={t('SMART:WhereAndHowLong.placeholders.dateTravel', {
                defaultValue:
                  i18nDefaultValues.WhereAndHowLong.placeholders.dateTravel,
              })}
              testId="travel-days"
            />
          </HelperText>
        </CalendarItem>
      ) : (
        <CalendarItem>
          <HelperText
            noMargin
            status={error ? 'error' : 'default'}
            message={getMessageErrorForEndDate(error)}
          >
            <Datepicker
              onCalendarOpen={toggleOpenEndDateCalendar}
              onCalendarClose={toggleOpenEndDateCalendar}
              disabledKeyboardNavigation
              ref={endDateCalendarRef}
              minDate={minDateForEndTravel}
              onChange={handleEndDate}
              selected={value?.end}
              label={t('SMART:WhereAndHowLong.labels.finishTravel', {
                defaultValue:
                  i18nDefaultValues.WhereAndHowLong.labels.finishTravel,
              })}
              maxDate={new Date(endDate)}
              error={
                !isOpenEndDateCalendar && !!getMessageErrorForEndDate(error)
              }
              {...addTestAttribute('travel-end-date')}
            />
          </HelperText>
        </CalendarItem>
      )}
    </CalendarStyle>
  );
};
