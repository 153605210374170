/* eslint-disable indent */
import { Button } from '@pulse-web-ui/button';

import { media, styled, typography } from '@pulse-web-ui/theme';

export const StyledContainer = styled.div`
  background: ${({ theme }) => theme?.colors?.surface?.primary};
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  ${media.desktop} {
    padding-bottom: 24px;
  }
`;

export const StyledPolicyLabel = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.medium20}

  ${media.desktop} {
    ${typography.medium24}
  }
`;

export const StyledLabel = styled.div<{ isShowSubLabelText: boolean }>`
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.medium16}
  line-height: 24px;
  padding-bottom: ${({ isShowSubLabelText }) =>
    isShowSubLabelText ? '8px' : '16px'};

  ${media.desktop} {
    ${typography.medium18}
  }
`;

export const StyledSubLabel = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.secondary};
  ${typography.regular16}
  line-height: 24px;
  padding-bottom: 16px;
`;

export const StyledClose = styled.div`
  z-index: 11;
  width: 33px;
  height: 33px;
  cursor: pointer;
`;

export const StyledBox = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  min-height: 24px;
`;

export const StyledPrice = styled.div`
  margin-left: auto;
  text-align: right;
  min-width: 100px;
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.regular12}
  line-height: 18px;
  ${media.desktop} {
    ${typography.regular14}
    line-height: 20px;
  }
`;

export const StyledBaseBox = styled.div`
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }

  ${media.desktop} {
    padding-bottom: 24px;
  }
`;

export const StyledOptionBox = styled.div`
  padding-bottom: 32px;
`;

export const StyledButton = styled(Button)`
  margin-top: 8px;
  width: 100%;
  ${media.desktop} {
    width: 138px;
  }
`;

export const StyledOption = styled.div`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme?.colors?.surface.background};
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme?.colors?.surface.primary};
  }
`;

export const StyledCheckIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTooltip = styled.div`
  margin-left: 6px;

  & > div > span {
    display: flex;
    align-items: center;
  }

  ${media.desktop} {
    margin-left: 8px;
  }
`;

export const StyledSubOptionLabel = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.medium14}
  line-height: 20px;
  background: ${({ theme }) => theme?.colors?.surface?.primary};

  ${media.desktop} {
    ${typography.medium16}
    line-height: 24px;
  }
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.regular12}
  line-height: 18px;
  padding: 0 4px;

  ${media.desktop} {
    ${typography.regular14}
    line-height: 20px;
  }
`;

export const StyledBtn = styled(Button)`
  margin-top: 16px;
  border-radius: 16px;
  width: 100%;

  ${media.desktop} {
    width: auto;
  }
`;

export const StyledHeaderBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

export const StyledTooltipText = styled.span`
  white-space: pre-line;
`;
