import { DemoSmartComponent } from '@entities/import-smart-components/demo-smart-component';
import { Spinner } from '@pulse-web-ui/spinner';
import { type FC, memo, Suspense, forwardRef } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';

//Компонент нужен для тестирования и настройки теорий, связанных с переносом в отдельный репозиторий смарт компонентов.
export const DemoSmartComponentAdapter: FC<SmartComponentAdapterProps<any>> =
  memo(
    forwardRef(({ onChange }) => {
      const value = {
        countries: ['Турция', 'Россия'],
        startDate: new Date(),
        endDate: new Date(),
        isPolicyYear: true,
        isAlredyTraveling: false,
      };

      return (
        <Suspense fallback={<Spinner />}>
          <DemoSmartComponent value={value} onChange={onChange} />
        </Suspense>
      );
    })
  );

DemoSmartComponentAdapter.displayName = 'DemoSmartComponentAdapter';
