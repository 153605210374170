import { memo, useCallback, useState } from 'react';
import type { FC, SyntheticEvent } from 'react';
import { t } from 'i18next';

import type { ProductPrice } from '@shared/types';
import { StyledCheckbox } from '@shared/styles';
import { addTestAttribute } from '@shared/utils';

import { i18nDefaultValues } from '../../i18n';
import { PromoCode } from './promo-code';

interface Props {
  price: ProductPrice;
  onChange: (promoCode: Partial<ProductPrice>) => void;
  onSubmit: (promoCode: string) => void;
  onClear: () => void;
}

export const PromoCodeOptional: FC<Props> = memo(
  ({ price, onChange, onSubmit, onClear }) => {
    const [hasPromoCode, setHasPromoCode] = useState(!!price.promoCode);
    const [localPromoCode, setLocalPromoCode] = useState(price.promoCode || '');

    const handleHasPromoCode = useCallback(
      ({ currentTarget: { checked } }: SyntheticEvent<HTMLInputElement>) => {
        setHasPromoCode(checked);
        if (!checked) {
          onChange({ promoCode: '' });
          setLocalPromoCode('');
          onClear();
        }
      },
      [setHasPromoCode, onChange, onClear]
    );

    const handleLocalPromocode = useCallback(
      (value: string) => {
        setLocalPromoCode(value);
        onChange({ promoCode: value, isApplyedPromo: false });
        onClear();
      },
      [onClear]
    );

    return (
      <>
        <StyledCheckbox
          checked={hasPromoCode}
          onChange={handleHasPromoCode}
          label={t('SMART:Submit.labels.havePromoCode', {
            defaultValue: i18nDefaultValues.Submit.labels.havePromoCode,
          })}
          {...addTestAttribute('travel-checkbox-have-promocode')}
        />
        {hasPromoCode && (
          <PromoCode
            onSubmit={onSubmit}
            onChange={handleLocalPromocode}
            promoCode={localPromoCode}
            price={price}
          />
        )}
      </>
    );
  }
);

PromoCodeOptional.displayName = 'PromoCodeOptional';
