/* eslint-disable indent */
import { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import type { FC, Ref } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spinner } from '@pulse-web-ui/spinner';
import { Modal } from '@pulse-web-ui/modal';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import OptionsList from 'smart-components/options-list';
import { useModalWidth, useAdjustBodyHeight } from '@shared/hooks';
import { addTestAttribute } from '@shared/utils';
import { sendAnalyticEvent, analyticEvents } from '@app/web-analytic';
import type { SmartComponentProps } from 'smart-components';

import { schema } from './schema';
import { RadioCard } from './components/radio-card';
import type {
  WantImprovePolicyOptions,
  WantImprovePolicyValues,
} from './want-improve-policy.types';
import { i18nDefaultValues } from './i18n';

import {
  Form,
  StyledContainer,
  StyledMoreBox,
  StyledSubHeaderLink,
} from './want-improve-policy.styles';

const WantImprovePolicy: FC<
  SmartComponentProps<WantImprovePolicyValues, WantImprovePolicyOptions>
> = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        options: { currency, coverageSum, initStateRisks },
      },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const [moreOptions, setMoreOptions] = useState<boolean>(false);
      const [openModal, setOpenModal] = useState<boolean>(false);
      const optionsModalRef = useRef<HTMLDivElement>(null);
      const { t } = useTranslation();
      const desktopWidth = useModalWidth();

      useAdjustBodyHeight(openModal, optionsModalRef);

      const {
        trigger,
        watch,
        control,
        setValue,
        formState: { isValid, errors },
      } = useForm<WantImprovePolicyValues>({
        defaultValues: value,
        resolver: yupResolver(schema),
        mode: 'all',
      });

      const { fields } = useFieldArray({
        control,
        name: 'fullOptionsRisks',
      });

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      useEffect(
        () => {
          setValue('errors', errors);
        },
        //Issue: https://github.com/react-hook-form/react-hook-form/issues/2893
        [JSON.stringify(errors)]
      );

      useEffect(() => {
        const opened = value?.fullOptionsRisks?.filter((item) => item.checked);
        if (opened && opened.length >= 4) {
          setMoreOptions(true);
        }
      }, [value?.fullOptionsRisks]);

      const checkQuantityRender = (index: number) => {
        if (moreOptions) {
          return true;
        }
        return index < (value?.fullOptionsRisks?.length || 0) - 3;
      };

      const handleOpenOptions = () => setMoreOptions(!moreOptions);

      const handleOpenModalClick = () => {
        setOpenModal(true);
        sendAnalyticEvent(analyticEvents.travelViewOptionsOptions);
      };

      const subHeader = (
        <Trans
          components={{
            span: <span />,
            styledLink: (
              <StyledSubHeaderLink
                onClick={handleOpenModalClick}
                {...addTestAttribute('travel-options-link')}
              />
            ),
          }}
        >
          {t('SMART:WantImprovePolicy.hints.expandOptions', {
            defaultValue:
              i18nDefaultValues.WantImprovePolicy.hints.expandOptions,
          })}
        </Trans>
      );

      if (!value?.fullOptionsRisks) {
        return <Spinner />;
      }

      return (
        <Form ref={forwardRef} {...addTestAttribute('improve-policy-block')}>
          <HeaderWithSubText
            title={t('SMART:WantImprovePolicy.headers.improvePolicy', {
              defaultValue:
                i18nDefaultValues.WantImprovePolicy.headers.improvePolicy,
            })}
            subTitle={subHeader}
          />
          <StyledContainer>
            {fields.map((field, index) => (
              <>
                {checkQuantityRender(index) && (
                  <Controller
                    key={field.id}
                    name={`fullOptionsRisks.${index}`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <RadioCard
                          value={field.value}
                          onChange={field.onChange}
                          currency={currency}
                        />
                      );
                    }}
                  />
                )}
                {!moreOptions &&
                  index === (value?.fullOptionsRisks?.length || 0) - 3 && (
                    <StyledMoreBox
                      onClick={handleOpenOptions}
                      {...addTestAttribute('travel-button-more-options')}
                    >
                      {t('SMART:WantImprovePolicy.labels.showAll', {
                        defaultValue:
                          i18nDefaultValues.WantImprovePolicy.labels.showAll,
                      })}
                    </StyledMoreBox>
                  )}
              </>
            ))}
            <Modal
              noMargin
              contentRef={optionsModalRef}
              desktopWidth={desktopWidth}
              showDialog={openModal}
              dangerouslyBypassScrollLock
            >
              <OptionsList
                risks={value?.fullOptionsRisks}
                currency={currency}
                coverageSum={coverageSum}
                initStateRisks={initStateRisks}
                onClose={() => setOpenModal(false)}
                handleMoveOptionStep={() => setOpenModal(false)}
                optionsListTitle={t(
                  'SMART:WantImprovePolicy.headers.includedInThePolicy',
                  {
                    defaultValue:
                      i18nDefaultValues.WantImprovePolicy.headers
                        .includedInThePolicy,
                  }
                )}
                basicProtectSubTitle={t(
                  'SMART:WantImprovePolicy.headers.basicOptions',
                  {
                    defaultValue:
                      i18nDefaultValues.WantImprovePolicy.headers.basicOptions,
                  }
                )}
              />
            </Modal>
          </StyledContainer>
        </Form>
      );
    }
  )
);

export default WantImprovePolicy;
