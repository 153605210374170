import { styled, css, typography } from '@pulse-web-ui/theme';
import { Button } from '@pulse-web-ui/button';
import { Input } from '@pulse-web-ui/input';

export const StyledInput = styled(Input)`
  border-radius: ${({ theme }) => `calc(${theme.common.radius} * 3)`} 0 0
    ${({ theme }) => `calc(${theme.common.radius} * 3)`};

  &:focus {
    border-right: none;
  }
`;

export const InputCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  width: 100%;
`;

export const InputCodeRow = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledButton = styled(Button)<{
  error?: boolean;
}>`
  border-radius: 0 ${({ theme }) => `calc(${theme.common.radius} * 3)`}
    ${({ theme }) => `calc(${theme.common.radius} * 3)`} 0;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.colors.text.error};
      background: ${theme.colors.text.error};
      &:hover {
        background: ${theme.colors.text.error} !important;
        border: 1px solid ${theme.colors.text.error} !important;
      }
    `}
`;

export const StyledChangePhoneButton = styled(Button)`
  ${typography.regular14};
  line-height: 20px;
`;
