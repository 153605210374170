import { Main } from '@app/store';
import { action, makeAutoObservable, observable } from 'mobx';

export class DraftStore {
  private mainStore: Main;

  checkDraft: boolean = true;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(
      this,
      {
        checkDraft: observable,
        setCheckDraft: action,
      },
      { autoBind: true }
    );
  }

  setCheckDraft = () => {
    this.checkDraft = false;
  };
}
