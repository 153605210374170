import type { FormStateType } from '@entities/index';
import { dateTimeReviver } from './date-time-reviver';
import { StorageItem } from '@shared/types';
import { startOfDay } from 'date-fns';
import { getNumberOfDays } from '@shared/utils';

type DraftType = {
  state?: {
    formState?: FormStateType;
  };
  common: {
    productType: string;
    userId: string;
  };
  promoCode?: string;
};

// Валидация дат для компонента ВЗР
const validateHowLong = (
  resData: DraftType,
  effectiveSinceFranchise?: string
) => {
  const start = resData?.state?.formState?.WhereAndHowLong?.travelDates?.start;
  const end = resData?.state?.formState?.WhereAndHowLong?.travelDates?.end;
  const traveling = resData?.state?.formState?.WhereAndHowLong?.traveling;

  const travellingGap = effectiveSinceFranchise
    ? Number(getNumberOfDays(effectiveSinceFranchise))
    : 1;

  if (start) {
    const minStartDate = startOfDay(new Date());
    minStartDate.setDate(
      startOfDay(new Date()).getDate() + (traveling ? travellingGap : 1)
    );

    if (
      start.getTime() < minStartDate.getTime() &&
      resData.state?.formState?.WhereAndHowLong
    ) {
      resData.state.formState.WhereAndHowLong.travelDates.start = undefined;
      if (end) {
        resData.state.formState.WhereAndHowLong.travelDates.end = undefined;
      }
    }
  } else if (end && resData.state?.formState?.WhereAndHowLong) {
    resData.state.formState.WhereAndHowLong.travelDates.end = undefined;
  }

  return resData;
};

export const getDraft = (effectiveSinceFranchise?: string) => {
  const selectedProduct = localStorage.getItem(StorageItem.SELECTED_PRODUCT);

  let lsDraft;
  let resData: DraftType | undefined;

  if (selectedProduct !== null && effectiveSinceFranchise) {
    lsDraft = localStorage.getItem(selectedProduct);
  }

  if (lsDraft) {
    resData = JSON.parse(lsDraft, dateTimeReviver);
    if (resData && effectiveSinceFranchise) {
      return { resData: validateHowLong(resData, effectiveSinceFranchise) };
    }
  }

  return { resData };
};
