/* eslint-disable indent */
import { getFormattedDate, getOptions } from '@shared/utils';

import type { GetPricesRequest } from '../types';
import { INSURANSE_SUM_DEFAULT } from '../constants';
import { getInsuranceObject, getRisks } from '../utils';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const RUSSIA_COUNTRY_CODE = '643';
const TERRITORIES_DEFAULT = [RUSSIA_COUNTRY_CODE];

export const getData = (
  formState: any,
  initState: any,
  currency: any,
  promoCode: string
) => {
  const dateBegin = new Date(
    formState?.WhereAndHowLong?.travelDates?.start || tomorrow
  );

  let dateEnd = new Date(
    formState?.WhereAndHowLong?.travelDates?.end || tomorrow
  );

  // Сброс чекбокса полис на год. Дата конца полиса не может быть меньше даты начала полиса.
  if (dateEnd.getTime() < dateBegin.getTime())
    dateEnd = new Date(dateBegin.getTime());

  const insuranceSum = Number(
    formState?.WhoAndHow?.coverageSum || INSURANSE_SUM_DEFAULT
  );

  const territoriesFromStore = formState?.WhereAndHowLong?.countries?.map(
    (country: any) => country.value
  );
  const territories =
    territoriesFromStore?.length > 0
      ? territoriesFromStore
      : TERRITORIES_DEFAULT;

  const annualPolicy = formState?.WhereAndHowLong?.policy || false;

  const numberOfDays =
    formState?.WhereAndHowLong?.travelDates?.duration || undefined;

  const sportHas = formState?.WhoAndHow?.sportHas;
  const sportKinds = formState?.WhoAndHow?.sportKinds || [];
  const activities = sportHas
    ? sportKinds.map((sport: { value: string }) => sport.value)
    : [];

  // защита от запроса цены ДО загрузки списка территорий
  const data: GetPricesRequest = {
    productVersion:
      initState.code && initState.territories ? initState.code : '',
    dateBegin: getFormattedDate(dateBegin),
    dateEnd: getFormattedDate(dateEnd),
    annualPolicy: annualPolicy,
    numberOfDays: Number(numberOfDays) || numberOfDays,
    insuranceSum: insuranceSum,
    currency: currency,
    risks: getRisks({
      fullOptionsRisks: formState?.WantImprovePolicy?.fullOptionsRisks || [],
      coverageSum: formState?.WhoAndHow?.coverageSum || INSURANSE_SUM_DEFAULT,
    }),
    options: getOptions({
      fullOptionsRisks: formState?.WantImprovePolicy?.fullOptionsRisks || [],
      traveling: Boolean(formState?.WhereAndHowLong?.traveling),
    }),
    insuranceObjects: getInsuranceObject(formState),
    territories: territories,
    activities: activities,
    promoCode: promoCode || undefined,
  };

  return data;
};
