/* eslint-disable @typescript-eslint/no-explicit-any */
import type { HeaderStep, SmartHeaderIconKey } from 'smart-components';

export enum VZRStep {
  calculation = 'calculation',
  options = 'options',
  formalization = 'formalization',
  payment = 'payment',
}
export type VZRProductConfig = ProductConfig<TravelGlobalVariables>;

export type TravelGlobalVariables = {
  otpStep: number;
  isShowBackButton: boolean; // Флаг отображения кнопки "Назад"
};

export type ProductConfig<T> = {
  name: string;
  header: ConfigSmartComponent[];
  footer: ConfigSmartComponent[];
  submit: ConfigSmartComponent[];
  flow: ProductStep[];
  globalVariables: T;
};

export type ProductStep = {
  components: ConfigSmartComponent[];
  icon: SmartHeaderIconKey;
  name: string;
  additionalVariables?: {
    [key: string]: any;
  };
};

export type ConfigSmartComponent = {
  name: string;
  additionalVariables?: {
    value?: any;
    [key: string]: any;
  };
};

export type FlowConfig<T> = T & {
  headerConfig: HeaderStep[];
  stepConfig: StepConfig[];
  maxSteps: number;
};

export type StepConfig = {
  step: number;
  value: {
    [key: string]: any;
  };
};
