import { media, styled, typography } from '@pulse-web-ui/theme';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme?.colors?.surface.primary};
  padding: 24px 16px;
  border-radius: ${({ theme }) =>
    theme.common?.radius12
      ? `calc(${theme.common.radius12} * 2)`
      : `calc(${theme.common.radius} * 6)`};
  row-gap: 24px;
  ${media.desktop} {
    row-gap: 32px;
    padding: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 16px;
  ${media.desktop} {
    flex-direction: row;
  }
  flex-direction: column;
`;

export const RowLast = styled.div`
  margin-bottom: -26px;
  display: flex;
  justify-content: flex-start;
  column-gap: 16px;
  ${media.desktop} {
    flex-direction: row;
  }
  flex-direction: column;
`;

export const RowSection = styled.div`
  display: flex;
  flex-basis: 50%;
  & > * {
    width: 100%;
  }
  &:first-child {
    padding-bottom: 4px;
  }
  ${media.desktop} {
    &:first-child {
      padding-bottom: 0;
    }
  }
`;

export const RowSectionBasic = styled.div``;

export const StyledLabel = styled.label`
  ${typography.medium20}
`;

export const WidgetSubHeaderText = styled.p`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 16px;
`;

export const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.desktop} {
    gap: 24px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AccordionLabel = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.secondary};
  ${typography.regular14}
  line-height: 20px;
`;

export const AccordionSubLabel = styled.div`
  ${typography.medium20}
  color: ${({ theme }) => theme?.colors?.text?.primary};
  margin-top: 4px;
`;
