import { Main } from '@app/store';
import { makeAutoObservable } from 'mobx';
import { VZRProductConfig } from 'mock';
export class ThemesStore {
  private mainStore: Main; // позволяет обращаться в другие сторы
  themeUrl?: string;
  appConfig?: VZRProductConfig;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setThemeUrl = (e: string) => {
    this.themeUrl = e;
  };

  setAppConfig = (e: VZRProductConfig) => {
    this.appConfig = e;
  };
}
