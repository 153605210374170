/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, memo, useEffect, useMemo } from 'react';
import type { FC, Ref } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import { CoverageSum, InsuredPersons, Sport, SportHas } from './components';
import { CoverageSumType } from './components/coverage-sum/model/types/schema';
import { getWhoAndHowSchema } from './schema';
import { getDefaultValues } from './utils';
import { Form, Row, RowSection } from './who-and-how.style';
import { i18nDefaultValues } from './i18n';
import type {
  Sport as SportType,
  WhoAndHowValues,
  WhoAndHowOptions,
  AgeSelectItem,
} from './who-and-how.type';
import { RISK_VALUE } from '@shared/constants';
import { useTranslation } from 'react-i18next';
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import type { TokenInputOption } from '@pulse-web-ui/token-input';
import type { SmartComponentProps } from 'smart-components';
import { addTestAttribute } from '@shared/utils';

const WhoAndHow: FC<SmartComponentProps<WhoAndHowValues, WhoAndHowOptions>> =
  memo(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          options: {
            maxObjectsNumber,
            risks,
            ageRange,
            currency,
            dateStart,
            travelers,
            activities,
            onChangeInsuredPersonsAge,
          },
        },
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const { t } = useTranslation();
        const schema = useMemo(() => getWhoAndHowSchema(), [t]);

        const {
          trigger,
          control,
          getValues,
          resetField,
          watch,
          setValue,
          formState: { isValid, errors },
        } = useForm<WhoAndHowValues>({
          defaultValues: getDefaultValues(
            value as WhoAndHowValues,
            travelers,
            dateStart
          ),
          mode: 'all',
          shouldFocusError: true,
          resolver: yupResolver(schema),
          context: {
            ageRange,
            dateStart,
          },
        });

        useEffect(() => {
          const subscription = watch((value) => {
            onChange(value as WhoAndHowValues);
          });

          return () => subscription.unsubscribe();
        }, [watch, isValid]);

        const coverageSumArray = useMemo<Array<number> | undefined>(
          () =>
            risks?.find((item: CoverageSumType) => item.code === RISK_VALUE)
              ?.coverageSum,
          [risks]
        );

        const handleChangeInsurane =
          (onChange: (value: AgeSelectItem[]) => void) =>
          (value: AgeSelectItem[]) => {
            onChange(value);
            onChangeInsuredPersonsAge(value);

            sendAnalyticEvent(analyticEvents.travelTravellersSelected);
          };

        const handleChangeCoverageSum =
          (onChange: (value: string) => void) =>
          (value: string, isMounting?: boolean) => {
            onChange(value);
            !isMounting && sendAnalyticEvent(analyticEvents.travelSumSelected);
          };

        const handleChangeSporthas =
          (onChange: (value: boolean) => void) => (value: boolean) => {
            onChange(value);

            sendAnalyticEvent(analyticEvents.travelSport);
          };

        const handleChaneSports =
          (onChange: (value: TokenInputOption[]) => void) =>
          (value: TokenInputOption[]) => {
            onChange(value);

            if (value.length) {
              sendAnalyticEvent(analyticEvents.travelSportSelected);
            }
          };
        useEffect(() => {
          setValue('isValid', isValid);
        }, [isValid]);

        useEffect(() => {
          if (isSubmitting) {
            trigger();
          }
        }, [isSubmitting]);

        useEffect(() => {
          const ageSelectValue = getValues('ageSelect');

          if (ageSelectValue) {
            onChangeInsuredPersonsAge(ageSelectValue);
          }
        }, []);

        useEffect(() => {
          const isSportChecked = getValues('sportHas');

          if (!isSportChecked) {
            resetField('sportKinds');
          }
        }, [watch('sportHas')]);

        return (
          <Form ref={forwardRef} {...addTestAttribute('who-and-how-block')}>
            <HeaderWithSubText
              title={t('SMART:WhoAndHow.title', {
                defaultValue: i18nDefaultValues.WhoAndHow.title,
              })}
              subTitle={t('SMART:WhoAndHow.subTitle', {
                count: maxObjectsNumber,
                defaultValue: i18nDefaultValues.WhoAndHow.subTitle,
              })}
            />
            <Row>
              <RowSection>
                <Controller
                  control={control}
                  name="ageSelect"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InsuredPersons
                      value={value}
                      onChange={handleChangeInsurane(onChange)}
                      error={error}
                      errors={errors}
                      maxPersons={maxObjectsNumber}
                    />
                  )}
                />
              </RowSection>
              <RowSection>
                <Controller
                  control={control}
                  name="coverageSum"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CoverageSum
                      coverageSum={coverageSumArray}
                      currency={currency}
                      value={value}
                      onChange={handleChangeCoverageSum(onChange)}
                      error={error}
                    />
                  )}
                />
              </RowSection>
            </Row>
            <Controller
              control={control}
              name="sportHas"
              render={({ field: { onChange, value } }) => (
                <SportHas
                  value={Boolean(value)}
                  onChange={handleChangeSporthas(onChange)}
                />
              )}
            />
            <Controller
              control={control}
              name="sportKinds"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return getValues('sportHas') ? (
                  <Sport
                    value={value as SportType[]}
                    onChange={handleChaneSports(onChange)}
                    error={error}
                    activities={activities}
                  />
                ) : (
                  <></>
                );
              }}
            />
          </Form>
        );
      }
    )
  );

export default WhoAndHow;
