import { useEffect } from 'react';

import { Endpoints, QUERY_KEYS } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { analyticEvents } from '@app/web-analytic';

import type { AuthenticationResponseType } from '../auth-box.type';
import { hasError, ErrorCode } from '@shared/utils';

export const useHandleConfirmAuthentication = (
  isLoadingAuthentication?: boolean,
  resAuthentication?: AuthenticationResponseType
) => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent },
      errorStore: { setErrorRetry },
      authStore: {
        callOtp,
        authenticationToken,
        profile,
        confirmationId,
        setDisableForm,
        setOtpIsLoading,
        setOtpErrorCode,
        handleOtpResponse,
      },
    },
  } = useStores();

  const requestData = {
    code: callOtp,
    confirmationId: resAuthentication?.confirmationId,
  };

  const { isLoading, error, res, refetch, remove } = useRequest(
    QUERY_KEYS.confirmAuth,
    'post',
    Endpoints.CONFIRMAUTH,
    requestData,
    [callOtp, resAuthentication?.authenticationToken],
    true,
    authenticationToken
  );

  useEffect(() => {
    const shouldRefetchOtp =
      callOtp &&
      !profile &&
      confirmationId &&
      resAuthentication &&
      authenticationToken &&
      !isLoadingAuthentication;

    if (shouldRefetchOtp) {
      setDisableForm(true);
      refetch();
    }
  }, [
    profile,
    callOtp,
    confirmationId,
    resAuthentication,
    authenticationToken,
    isLoadingAuthentication,
  ]);

  useEffect(() => {
    setOtpIsLoading(isLoading);

    if (!isLoading && res && res.accessToken) {
      handleOtpResponse(res);
      setOtpErrorCode(false);
      setErrorRetry(false);
      sendSingleAnalyticEvent(analyticEvents.travelerEnterCodeSuccess);
    }

    const isUnknownUserOrCode =
      !isLoading && hasError(error, ErrorCode.UNKNOWN_USER_OR_CODE);

    if (isUnknownUserOrCode) {
      setOtpErrorCode(true);
    }
  }, [isLoading, error, res]);

  return {
    isLoadingConfirmAuthentication: isLoading,
    errorConfirmAuthentication: error,
    resConfirmAuthentication: res,
    refetchConfirmAuthentication: refetch,
    removeConfirmAuthentication: remove,
  };
};
