import { memo, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { Form, StyledIframe, B2pWrapper } from './b2p.style';
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { addTestAttribute } from '@shared/utils';
import type { SmartComponentProps } from 'smart-components/shared/types';
import type { B2PValues } from './b2p.type';
import { getPurchaseRoute } from './utils/helpers';
import { B2PInfoModal } from './components';

const B2P: FC<SmartComponentProps<B2PValues, null>> = memo(({ value }) => {
  const ref = useRef<null | HTMLDivElement>(null);

  const route = getPurchaseRoute(value);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (value?.showInitOrder) {
      setShowModal(Boolean(value?.showInitOrder));
      sendAnalyticEvent(analyticEvents.travelToStepPay);
    }
  }, [value?.showInitOrder]);

  // Оплата скрыта
  if (!value?.showInitOrder) return null;
  // Оплата на сайте
  if (!value?.isExternalPayment) {
    return (
      <B2pWrapper ref={ref} {...addTestAttribute('B2P-block')}>
        <Form>
          <StyledIframe
            src={route}
            allow="payment"
            sandbox="allow-top-navigation allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
          />
        </Form>
      </B2pWrapper>
    );
  }
  // Оплата в отдельном окне
  return <B2PInfoModal showModal={showModal} setShowModal={setShowModal} />;
});

export default B2P;
