import { Product } from '@shared/constants';
import type { FormStateType } from '@entities/index';

interface DataObjectType {
  formState: Partial<FormStateType>;
}

export type DraftProps = {
  dataObject: DataObjectType;
  selectedProduct: Product | undefined;
  promoCode?: string;
};

export const setDraftLS = (props: DraftProps) => {
  const { dataObject, selectedProduct, promoCode } = props;

  if (selectedProduct) {
    return localStorage.setItem(
      `${selectedProduct}`,
      JSON.stringify({
        state: dataObject,
        common: {
          selectedProduct,
        },
        promoCode: promoCode,
      })
    );
  }

  return;
};
