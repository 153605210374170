import React from 'react';
import { observer } from 'mobx-react-lite';

import { AuthBox } from '@features/auth-box';
import { useStores } from '@shared/hooks';

import type { FC } from 'react';
import type { WhoIssuesPolicyDataType } from '@features/auth-box';

import { getRegistrationData } from './utils';

type AuthBoxAdapterProps = {
  updateFormValue: (fieldName: string, value: any) => void;
  setFormValueError: (fieldName: string, error: any) => void;
};

export const AuthBoxAdapter: FC<AuthBoxAdapterProps> = observer(
  ({ updateFormValue, setFormValueError }) => {
    const {
      MainStore: {
        productStore: { formState },
        initProductStore: { regUtm },
        authStore: { promoAgreement },
      },
    } = useStores();

    const registrationData = getRegistrationData(
      formState,
      regUtm,
      promoAgreement
    );

    return (
      <AuthBox
        registrationData={registrationData}
        updateFormValue={updateFormValue}
        setFormValueError={setFormValueError}
        whoIssuesPolicyData={
          formState?.WhoIssuesPolicy as WhoIssuesPolicyDataType
        }
      />
    );
  }
);

AuthBoxAdapter.displayName = 'AuthBoxAdapter';
