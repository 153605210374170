import { getFormattedDate } from '@shared/utils';

import type { RegistrationRequestDataType } from '@features/auth-box';

type GetRegistrationDataFn = (
  formState: any,
  regUtm: Record<string, any> | undefined,
  promoAgreement: boolean
) => RegistrationRequestDataType;

export const getRegistrationData: GetRegistrationDataFn = (
  formState,
  regUtm,
  promoAgreement
) => {
  const extendedIntermediaryChannelCode = `${regUtm?.media_source ?? '0000'},${
    regUtm?.campaign ?? 'WEB'
  }`;

  const userPolicies = [
    { approvedAt: new Date(), code: '003' },
    { approvedAt: new Date(), code: '005' },
    ...(promoAgreement ? [{ approvedAt: new Date(), code: '002' }] : []),
  ];

  const registrationData: RegistrationRequestDataType = {
    user: {
      userPolicies,
      extendedIntermediaryChannelCode,
      firstName: formState?.WhoIssuesPolicy?.name?.trim(),
      lastName: formState?.WhoIssuesPolicy?.surname?.trim(),
      middleName: formState?.WhoIssuesPolicy?.secondName?.trim() || '',
      clientCategory: 'STANDART',
      utmSource: regUtm?.utm_source || '',
      utmMedium: regUtm?.utm_medium || '',
      utmCampaign: regUtm?.utm_campaign || '',
      utmContent: regUtm?.utm_content || '',
      wmId: regUtm?.wm_id || '',
      birthDay: formState?.WhoIssuesPolicy?.birthDate
        ? getFormattedDate(formState?.WhoIssuesPolicy?.birthDate)
        : '',
      email: formState?.WhoIssuesPolicy?.email,
    },
  };

  return registrationData;
};
