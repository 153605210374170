import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@pulse-web-ui/input';
import { addTestAttribute } from '@shared/utils';

import { i18nDefaultValues } from '../../i18n';

import type { Props } from './input-phone.types';

export const InputPhone: FC<Props> = ({ value, disabled, onChange }) => {
  const { t } = useTranslation();

  return (
    <Input
      type="tel"
      label={t('SMART:Submit.labels.phone', {
        defaultValue: i18nDefaultValues.Submit.labels.phone,
      })}
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...addTestAttribute('otp-phone')}
    />
  );
};
