import { t } from 'i18next';
import { lazy, Suspense } from 'react';

const CallIcon = lazy(() => import('@pulse-web-ui/icons/lib/lazy/rgs-call'));
const LicenceIcon = lazy(
  () => import('@pulse-web-ui/icons/lib/lazy/rgs-licence')
);
const MessageIcon = lazy(
  () => import('@pulse-web-ui/icons/lib/lazy/rgs-message')
);

export const policyDone = [
  {
    icon: (
      <Suspense>
        <MessageIcon width={64} />
      </Suspense>
    ),
    text: t('COMMON:pay.callUs'),
  },
  {
    icon: (
      <Suspense>
        <CallIcon width={64} />
      </Suspense>
    ),
    text: t('COMMON:pay.getConsultation'),
  },
  {
    icon: (
      <Suspense>
        <LicenceIcon width={64} />
      </Suspense>
    ),
    text: t('COMMON:pay.viewUsefulMaterials'),
  },
];
