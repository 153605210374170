import type {
  ProductPrice,
  CurrencyType as CurrencyTypeString,
  RisksType,
} from '@shared/types';

// TODO: Экспортируется в проект.
export type FooterSmartComponentValue = {
  price: ProductPrice;
  countries?: string[];
  options?: number;
  insuranceSumm?: string;
  insuranceSummCurrency: keyof typeof CurrencyType;
  dates?: { startDate?: Date; endDate?: Date };
};

export type footerShowOptions = {
  displayOptions: true;
};

// TODO: Экспортируется в проект.
export interface FooterSmartComponentProps {
  value: FooterSmartComponentValue;
  setActiveStepByName: VoidFn<string>;
  activeStep: number;
  hasPricesCalcErr: boolean;
  currency: CurrencyTypeString;
  risks: RisksType[] | undefined;
  coverageSum: string | undefined;
  initStateRisks: RisksType[] | undefined;
  selectedRisksLabel: string;
  maxSteps: number | undefined;
}

//TODO: Dublicate
export enum CurrencyType {
  USD = '$',
  EUR = '€',
  RUB = '₽',
}
