import { StyledCheckbox } from '@shared/styles';
import type { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipDirection, TriggerDirection } from '@pulse-web-ui/tooltip';

import { addTestAttribute } from '@shared/utils';

import { SportHasStyle } from './sport-has.style';
import { i18nDefaultValues } from '../../i18n';
import type { Props } from './sport-has.type';

export const SportHas: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    onChange(target.checked);
  };

  return (
    <SportHasStyle>
      <StyledCheckbox
        checked={value}
        onChange={handleChange}
        hasTooltip
        label={t('SMART:WhoAndHow.labels.planPlaySports', {
          defaultValue: i18nDefaultValues.WhoAndHow.labels.planPlaySports,
        })}
        tooltipOptions={{
          trigger: TriggerDirection.HOVER,
          direction: TooltipDirection.UP,
          tooltipText: t('SMART:WhoAndHow.hints.selectedSports', {
            defaultValue: i18nDefaultValues.WhoAndHow.hints.selectedSports,
          }),
        }}
        {...addTestAttribute('travel-checkbox-sport')}
      />
    </SportHasStyle>
  );
};
