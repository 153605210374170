import { B2PValues } from '../b2p.type';

export const getPurchaseRoute = (value: B2PValues | undefined): string => {
  const sector = `sector=${value?.initOrder?.sector}`;
  const id = `id=${value?.initOrder?.id}`;
  const signature = `signature=${value?.initOrder?.signature}`;
  const url = `url=${value?.initOrder?.url}`;
  const failUrl = `failUrl=${value?.initOrder?.failUrl}`;
  const get_token = 1;

  const propsString = [sector, id, signature, url, failUrl, get_token].join(
    '&'
  );

  return `${window.envUrls.BEST2PAY_API_URL}/Purchase?${propsString}`;
};
