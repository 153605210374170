import { Schema } from 'yup';

export const validateData = <T>(data: T, schema: Schema<T>, options?: any) => {
  try {
    schema.validateSync(data, options);
    return true;
  } catch (error) {
    return false;
  }
};
