import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

import { TokenInput } from '@pulse-web-ui/token-input';
import type { TokenInputOption } from '@pulse-web-ui/token-input';
import { StyledInputWrapper } from './sport.style';
import { i18nDefaultValues } from '../../i18n';

import type { Props } from './sport.type';

export const Sport: FC<Props> = ({ value, onChange, error, activities }) => {
  const { t } = useTranslation();
  const [sportsList, setSportsList] = useState<TokenInputOption[]>([]);

  useEffect(() => {
    const newArr: TokenInputOption[] = [];
    if (activities && activities.length > 0) {
      activities.forEach((item) =>
        newArr.push({ label: item.name, value: item.code })
      );
      setSportsList(newArr);
    }
  }, [activities]);

  const handleChange = (value: TokenInputOption[]) => {
    onChange(value);
  };

  const tokenInputValue = value as TokenInputOption[];

  return (
    <StyledInputWrapper>
      <TokenInput
        noMargin
        value={tokenInputValue}
        onChange={handleChange}
        options={sportsList}
        label={t('SMART:WhoAndHow.labels.enterSportName', {
          defaultValue: i18nDefaultValues.WhoAndHow.labels.enterSportName,
        })}
        error={!!error}
        hintObject={{
          message: error?.message,
        }}
        testId="travel-sport-kinds"
      />
    </StyledInputWrapper>
  );
};
