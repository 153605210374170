export const i18nDefaultValues = {
  SmartHeader: {
    labels: {
      calculation: 'Расчет',
      options: 'Опции',
      formalization: 'Оформление',
      payment: 'Оплата',
      clients: 'Клиенты',
    },
  },
};
