/* eslint-disable indent */
import { addTestAttribute, numFormat, scrollToIframe } from '@shared/utils';
import {
  Suspense,
  lazy,
  memo,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { format } from 'date-fns';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import OptionsList from 'smart-components/options-list';

import { Modal } from '@pulse-web-ui/modal';

import { ItemCard } from './footer-smart-component-item';
import {
  DateView,
  DesktopView,
  FooterWrapper,
  MobileView,
  StyledCardList,
  StyledCardListItem,
  StyledButton,
} from './footer-smart-component.styles';
import { CurrencyType } from './footer-smart-component.types';
import type { FooterSmartComponentProps } from './footer-smart-component.types';
import { useAdjustBodyHeight, useModalWidth } from '@shared/hooks';
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { ItemPrice } from './footer-smart-component-price';

import { i18nDefaultValues } from './i18n';

const Info = lazy(() => import('@pulse-web-ui/icons/lib/lazy/info'));

const FooterSmartComponent: FC<FooterSmartComponentProps> = memo(
  ({
    value,
    setActiveStepByName,
    activeStep,
    hasPricesCalcErr,
    risks,
    currency,
    maxSteps,
    coverageSum,
    initStateRisks,
    selectedRisksLabel,
  }) => {
    const { t } = useTranslation();
    const [dates, setDates] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const optionsModalRef = useRef<HTMLDivElement>(null);
    const desktopWidth = useModalWidth();

    useAdjustBodyHeight(isModalOpen, optionsModalRef);

    const closeHandler = () => {
      setIsOpen(false);
    };

    const openHandler = () => {
      setIsOpen(true);
      sendAnalyticEvent(analyticEvents.travelSummary);
      scrollToIframe();
    };

    const showOptionsHandler = () => {
      setIsModalOpen(true);
      scrollToIframe();
      sendAnalyticEvent(analyticEvents.travelViewOptionsFooter);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
      if (value?.dates) {
        let str = '';
        if (value.dates.startDate) {
          str = str + format(value.dates.startDate, 'dd.MM.yyyy');
        }
        if (value.dates.endDate) {
          str = str + ' - ' + format(value.dates.endDate, 'dd.MM.yyyy');
        }
        setDates(str);
      }
    }, [value]);

    const counryStr =
      value?.countries && value?.countries?.length > 1
        ? String(value?.countries.join(', '))
        : String(value?.countries);

    const modalCountryList = useMemo(() => {
      return !!(value?.countries && value?.countries?.length) ? (
        <StyledCardList>
          {value.countries.map((country) => (
            <StyledCardListItem>{country}</StyledCardListItem>
          ))}
        </StyledCardList>
      ) : (
        t('SMART:FooterSmartComponent.labels.notSelected', {
          defaultValue:
            i18nDefaultValues.FooterSmartComponent.labels.notSelected,
        })
      );
    }, [value?.countries]);

    const handleMoveOptionStep = () => {
      closeHandler();
      handleCloseModal();
      setActiveStepByName('options');
    };

    const showDates = value?.dates?.startDate && dates;

    return (
      <FooterWrapper {...addTestAttribute('footer-block')}>
        <ItemPrice
          price={value.price}
          errorState={hasPricesCalcErr}
          activeStep={activeStep}
          maxSteps={maxSteps}
        />
        <DesktopView>
          {(value?.countries && (
            <ItemCard
              name={t('SMART:FooterSmartComponent.labels.countryAndRegion', {
                defaultValue:
                  i18nDefaultValues.FooterSmartComponent.labels
                    .countryAndRegion,
              })}
              value={`${
                counryStr ||
                t('SMART:FooterSmartComponent.labels.notSelected', {
                  defaultValue:
                    i18nDefaultValues.FooterSmartComponent.labels.notSelected,
                })
              }`}
              labelTest="Countries"
            />
          )) ||
            null}
        </DesktopView>
        {(value?.options || value?.options === 0) && (
          <ItemCard
            name={t('SMART:FooterSmartComponent.labels.included', {
              defaultValue:
                i18nDefaultValues.FooterSmartComponent.labels.included,
            })}
            value={selectedRisksLabel}
            action={showOptionsHandler}
            labelTest="Included-counter"
          />
        )}
        <DesktopView>
          {value?.insuranceSumm && (
            <ItemCard
              name={t('SMART:FooterSmartComponent.labels.insuranceSum', {
                defaultValue:
                  i18nDefaultValues.FooterSmartComponent.labels.insuranceSum,
              })}
              value={`${numFormat(Number(value.insuranceSumm))} ${
                value.insuranceSummCurrency &&
                CurrencyType[value.insuranceSummCurrency || null]
              }`}
              labelTest="Insurance-sum"
            />
          )}
        </DesktopView>
        <DateView>
          <ItemCard
            name={t('SMART:FooterSmartComponent.labels.dates', {
              defaultValue: i18nDefaultValues.FooterSmartComponent.labels.dates,
            })}
            value={
              showDates
                ? dates
                : t('SMART:FooterSmartComponent.errors.notSelected', {
                    defaultValue:
                      i18nDefaultValues.FooterSmartComponent.errors.notSelected,
                  })
            }
            labelTest="Dates"
          />
        </DateView>
        <MobileView {...addTestAttribute('show-mobile-modal')}>
          <StyledButton
            icon={
              <Suspense>
                <Info width={24} color="active" />
              </Suspense>
            }
            variant="square-secondary"
            buttonSize="s"
            onClick={openHandler}
          />
        </MobileView>
        <Modal
          noMargin
          contentRef={optionsModalRef}
          desktopWidth={desktopWidth}
          showDialog={isModalOpen}
          dangerouslyBypassScrollLock
        >
          <OptionsList
            risks={risks}
            currency={currency}
            coverageSum={coverageSum}
            initStateRisks={initStateRisks}
            onClose={handleCloseModal}
            handleMoveOptionStep={handleMoveOptionStep}
            optionsListTitle={t(
              'SMART:FooterSmartComponent.headers.includedInThePolicy',
              {
                defaultValue:
                  i18nDefaultValues.FooterSmartComponent.headers
                    .includedInThePolicy,
              }
            )}
            basicProtectSubTitle={t(
              'SMART:FooterSmartComponent.headers.basicOptions',
              {
                defaultValue:
                  i18nDefaultValues.FooterSmartComponent.headers.basicOptions,
              }
            )}
          />
        </Modal>
        <Modal
          showDialog={isOpen}
          closeHandler={closeHandler}
          closeIcon
          dangerouslyBypassScrollLock
        >
          <div>
            {(value?.countries && (
              <ItemCard
                name={t('SMART:FooterSmartComponent.labels.countryAndRegion', {
                  defaultValue:
                    i18nDefaultValues.FooterSmartComponent.labels
                      .countryAndRegion,
                })}
                value={modalCountryList}
                labelTest="Countries"
              />
            )) ||
              null}
            {(value?.options || value?.options === 0) && (
              <ItemCard
                name={t('SMART:FooterSmartComponent.labels.included', {
                  defaultValue:
                    i18nDefaultValues.FooterSmartComponent.labels.included,
                })}
                value={selectedRisksLabel}
                action={showOptionsHandler}
                labelTest="Countries"
              />
            )}
            {(value?.insuranceSumm && (
              <ItemCard
                name={t('SMART:FooterSmartComponent.labels.insuranceSum', {
                  defaultValue:
                    i18nDefaultValues.FooterSmartComponent.labels.insuranceSum,
                })}
                value={`${numFormat(Number(value.insuranceSumm))} ${
                  value.insuranceSummCurrency &&
                  CurrencyType[value.insuranceSummCurrency || null]
                }`}
                labelTest="Included-counter"
              />
            )) ||
              null}
            <ItemCard
              name={t('SMART:FooterSmartComponent.labels.dates', {
                defaultValue:
                  i18nDefaultValues.FooterSmartComponent.labels.dates,
              })}
              value={
                showDates
                  ? dates
                  : t('SMART:FooterSmartComponent.errors.notSelected', {
                      defaultValue:
                        i18nDefaultValues.FooterSmartComponent.errors
                          .notSelected,
                    })
              }
              labelTest="Dates"
            />
          </div>
        </Modal>
      </FooterWrapper>
    );
  }
);

FooterSmartComponent.displayName = 'FooterSmartComponent';
export default FooterSmartComponent;
