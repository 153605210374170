import { StyledCheckbox } from '@shared/styles';
import { useTranslation } from 'react-i18next';

import { TooltipDirection, TriggerDirection } from '@pulse-web-ui/tooltip';

import { useIsDesktop } from '@shared/hooks';
import { addTestAttribute } from '@shared/utils';

import { PolicyStyle } from './policy.style';

import type { FC, SyntheticEvent } from 'react';
import { Props } from './policy.type';

import { i18nDefaultValues } from '../../i18n';

export const Policy: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    onChange(target.checked);
  };

  return (
    <PolicyStyle>
      <StyledCheckbox
        checked={value}
        onChange={handleChange}
        hasTooltip={isDesktop}
        label={t('SMART:WhereAndHowLong.labels.policyOnYear', {
          defaultValue: i18nDefaultValues.WhereAndHowLong.labels.policyOnYear,
        })}
        tooltipOptions={{
          trigger: TriggerDirection.HOVER,
          direction: TooltipDirection.UP,
          tooltipText: t('SMART:WhereAndHowLong.hints.forAvidTravelers', {
            defaultValue:
              i18nDefaultValues.WhereAndHowLong.hints.forAvidTravelers,
          }),
        }}
        {...addTestAttribute('travel-checkbox-year-policy')}
      />
    </PolicyStyle>
  );
};
