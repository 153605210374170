import { UnicodeEnum } from '@shared/constants';

export const i18nDefaultValues = {
  Submit: {
    labels: {
      codeFromSMS: 'Код из СМС',
      changePhone: 'Изменить телефон',
      phone: 'Телефон',
      continue: 'Продолжить',
      toSelectOptions: 'К выбору опций',
      toRegister: 'К оформлению',
      applied: 'Применён',
      apply: 'Применить',
      promoCode: 'Промокод',
      havePromoCode: 'У меня есть промокод',
    },
    hints: {
      agreeReceiveOffers: `Соглашаюсь на получение${UnicodeEnum.NBSP}<a href="{{href}}" target="_blank" download> специальных предложений, скидок и полезных рекомендаций</a>`,
      getNewCode: 'Получить новый код',
      through: 'через',
      overPriceError: `Мы пока не можем оформить полис стоимостью более 40${UnicodeEnum.NBSP}000${UnicodeEnum.NBSP}₽. Снизьте стоимость изменив условия`,
      siteAgreements:
        '<SiteAgreements order="0">Нажимая кнопку «Продолжить», вы соглашаетесь <br/>на обработку <StyledPolicyLink href="{{href}}" target="_blank" download>персональных данных</StyledPolicyLink></SiteAgreements>',
      discount: 'Скидка {{discount}}%',
    },
    errors: {
      promoCodeNotValid: 'Такого промокода нет или он больше не действует',
      incorrectCodeFromSms: 'Неверный код из SMS',
    },
  },
};
