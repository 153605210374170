/* eslint-disable indent */
import { StateBlock } from '@pulse-web-ui/state-block';
import { media, styled } from '@pulse-web-ui/theme';

export const StateBlockStyled = styled(StateBlock)`
  ${media.desktop} {
    white-space: pre;
  }
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: auto;
  ${media.desktop} {
    flex-direction: row;
    width: 100%;
    justify-content: center;
    max-width: 640px;
  }
  & button {
    width: 280px;
    ${media.desktop} {
      width: 100%;
    }
  }
`;

export const ButtonContainerStyled = styled.div`
  flex-basis: auto;
  text-align: center;
  ${media.desktop} {
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
`;
