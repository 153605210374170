/* eslint-disable @typescript-eslint/no-explicit-any */

import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { FormBuilderUpdateHandler } from '@features/form-builder';
import {
  FormBuilderAction,
  FormBuilderEventBus,
} from '@features/form-builder/utils';
import { action, configure, makeAutoObservable, observable } from 'mobx';
import type { FlowConfig } from 'mock';

export const analyticEventSteps = [
  analyticEvents.travelToStepTravelData,
  analyticEvents.travelToStepAddOptions,
  analyticEvents.travelToStepData,
  analyticEvents.travelToStepDocs,
];

configure({
  enforceActions: 'always',
});

export class ApplicationStore<T> {
  loading = true; // Отображение лоудера пока получаем данные для инициализации приложения
  disabledSubmit: boolean = true; // Для дизейбла кнопки перехода на следующий экран
  paymenStep: boolean = false; // Переменная для отображения компонента оплаты
  retryPayment: boolean = false; // Когда попадаем на страницу ошибки оплаты (/failed-pay), заново пройти оплату
  activeStep: number = 1;
  currentStep: number = 1;
  wantNextStep: boolean = false; // Для перехода на следующий шаг приложения
  deviceType: string = ''; // url параметр приходящий в адресной строке, признак, что пользователь перешёл с мобильного приложения в веб
  phoneFromMobileApp: string = ''; // url параметр приходящий в адресной строке, признак, что пользователь перешёл с мобильного приложения в веб
  flowConfig: Partial<FlowConfig<T>> = {}; // Конфиг приложения.
  isHeaderShowing = true; // Признак отображения хедера.
  isFooterShowing = true; // Признак отображения футера.
  isSmartComponentsShowing = true; // Признак отображения смарт компонентов.
  isSubmitShowing = true; // Признак отображения сабмита.
  isResetFormState = false; // Переопределить состояние формы из стора.
  sendedAnalyticEvents = new Set(); // Отправленные аналитики.
  isExternalPayment = true; // Переключение флоу оплаты в отдельную вкладку браузера.

  constructor() {
    makeAutoObservable(this, {
      loading: observable,
      currentStep: observable,
      wantNextStep: observable,
      activeStep: observable,
      paymenStep: observable,
      retryPayment: observable,
      flowConfig: observable,
      setFlowConfig: observable,
      isHeaderShowing: observable,
      isFooterShowing: observable,
      isSmartComponentsShowing: observable,
      isSubmitShowing: observable,
      isResetFormState: observable,
      sendedAnalyticEvents: observable,
      isExternalPayment: observable,
      sendSingleAnalyticEvent: action,
      getSendedAnalyticEvent: action,
      clearSendedAnalyticEvent: action,
      setLoading: action,
      setCurrentStep: action,
      setWantNextStep: action,
      setActiveStep: action,
      setPaymenStep: action,
      setRetryPayment: action,
      deviceType: observable,
      setDeviceType: action,
      phoneFromMobileApp: observable,
      setPhoneFromMobileApp: action,
      setActiveStepByName: action,
      activeStepHasName: action,
      setIsHeaderShowing: action,
      setIsFooterShowing: action,
      setIsSmartComponentsShowing: action,
      setIsSubmitShowing: action,
      setIsResetFormState: action,
      updateFormValue: action,
      handleGoBack: action,
      hidePaymentComponent: action,
      setIsExternalPayment: action,
    });
  }

  setLoading = (status: boolean) => {
    this.loading = status;
  };

  setCurrentStep = (e: number) => {
    this.currentStep = e;
  };

  setWantNextStep = (e: boolean) => {
    this.wantNextStep = e;
  };

  setActiveStep = (e: number) => {
    this.activeStep = e;
    // Последнее событие отправляем только после успешного скоринга
    if (this.flowConfig?.maxSteps && e < this.flowConfig?.maxSteps)
      sendAnalyticEvent(analyticEventSteps[e - 1]);
  };

  setActiveStepByName = (e: string) => {
    const index =
      this.flowConfig.headerConfig?.findIndex((item) => item.name === e) || -1;

    if (~index) this.setActiveStep(index + 1);
  };

  activeStepHasName = <T extends string>(e: T): boolean =>
    this.flowConfig.headerConfig?.findIndex((item) => item.name === e) ==
      this.activeStep - 1 || false;

  setFlowConfig = (e: Partial<FlowConfig<T>>) => {
    this.flowConfig = e;
  };

  setPaymenStep = (e: boolean) => {
    this.paymenStep = e;
  };

  setRetryPayment = (e: boolean) => {
    this.retryPayment = e;
  };

  setDeviceType = (e: string) => {
    this.deviceType = e;
  };

  setPhoneFromMobileApp = (e: string) => {
    this.phoneFromMobileApp = e;
  };

  setDisabledSubmit = (e: boolean) => {
    this.disabledSubmit = e;
  };

  setIsHeaderShowing = (e: boolean) => {
    this.isHeaderShowing = e;
  };

  setIsFooterShowing = (e: boolean) => {
    this.isFooterShowing = e;
  };

  setIsSmartComponentsShowing = (e: boolean) => {
    this.isSmartComponentsShowing = e;
  };

  setIsSubmitShowing = (e: boolean) => {
    this.isSubmitShowing = e;
  };

  setIsResetFormState = (e: boolean) => {
    this.isResetFormState = e;
  };

  sendSingleAnalyticEvent = (e: string) => {
    if (!this.sendedAnalyticEvents.has(e)) {
      this.sendedAnalyticEvents.add(e);
      sendAnalyticEvent(e);
    }
  };

  getSendedAnalyticEvent = (e: string): boolean => {
    return this.sendedAnalyticEvents.has(e);
  };

  clearSendedAnalyticEvent = (e?: string | string[]) => {
    if (!e) return this.sendedAnalyticEvents.clear();

    const events = Array.isArray(e) ? e : [e];

    events.forEach((event) => this.sendedAnalyticEvents.delete(event));
  };

  updateFormValue: FormBuilderUpdateHandler = (fieldName, value) => {
    FormBuilderEventBus.publish(FormBuilderAction.UPDATE_FORM, {
      fieldName,
      value,
    });
  };

  clearFormValue = () => {
    FormBuilderEventBus.reset();
  };

  handleGoBack = () => {
    this.setActiveStep(this.activeStep - 1);
  };

  hidePaymentComponent = () => {
    this.updateFormValue('B2P', {
      initOrder: undefined,
      showInitOrder: false,
      isValid: true,
    });
    this.setPaymenStep(false);
  };

  setIsExternalPayment = (e: boolean) => {
    this.isExternalPayment = e;
  };
}
