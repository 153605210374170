/* eslint-disable @typescript-eslint/no-explicit-any */
import { B2P } from './b2p';
import type { FC } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';

import {
  DemoSmartComponentAdapter,
  DocumentsListAdapter,
  FooterAdapter,
  HeaderAdapter,
  SubmitAdapter,
  WantImprovePolicyAdapter,
  WhereAndHowLongAdapter,
  WhoAndHowAdapter,
  WhoAndHowPaperworkAdapter,
  WhoIssuesPolicyAdapter,
  withCommonSmartComponentAdapter,
} from '@entities/adapters';

type TypeImportSmartComponents = {
  [key: string]: FC<SmartComponentAdapterProps<any>>;
};

export const importSmartComponents: TypeImportSmartComponents = {
  DemoSmartComponent: DemoSmartComponentAdapter,
  WhereAndHowLong: WhereAndHowLongAdapter,
  WhoAndHowPaperwork: WhoAndHowPaperworkAdapter,
  WhoAndHow: WhoAndHowAdapter,
  WantImprovePolicy: WantImprovePolicyAdapter,
  WhoIssuesPolicy: WhoIssuesPolicyAdapter,
  DocumentsList: DocumentsListAdapter,
  B2P: withCommonSmartComponentAdapter(B2P),
};

type TypeImportCommonComponents = {
  [key: string]: FC<any>;
};

export const importCommonComponents: TypeImportCommonComponents = {
  Header: HeaderAdapter,
  Footer: FooterAdapter,
  Submit: SubmitAdapter,
};
