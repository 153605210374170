import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TokenInput } from '@pulse-web-ui/token-input';
import type { TokenInputOption } from '@pulse-web-ui/token-input';

import type { Props } from './country.type';
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';

import { i18nDefaultValues } from '../../i18n';

export const Country: FC<Props> = ({ value, onChange, error, territories }) => {
  const { t } = useTranslation();

  const [allCountry, setAllCountry] = useState<TokenInputOption[]>();
  const [suggestCountry, setSuggestCountry] = useState<TokenInputOption[]>();

  const handleChange = (value: TokenInputOption[]) => {
    onChange(value);

    if (value.length) {
      sendAnalyticEvent(analyticEvents.travelCountrySelected);
    }
  };

  const tokenInputValue = value as TokenInputOption[];

  useEffect(() => {
    if (territories && territories.length > 0) {
      const newCList: TokenInputOption[] = [];
      const newSuggList: TokenInputOption[] = [];
      territories.forEach((item) => {
        newCList.push({
          label: item.name,
          value: item.code,
        });
        if (item.displayCategory) {
          newSuggList.push({
            label: item.name,
            value: item.code,
          });
        }
      });
      setAllCountry(newCList);
      setSuggestCountry(newSuggList);
    }
  }, [territories]);

  return (
    <TokenInput
      value={tokenInputValue}
      onChange={handleChange}
      options={allCountry}
      suggestions={suggestCountry}
      label={t('SMART:WhereAndHowLong.labels.countryTravel', {
        defaultValue: i18nDefaultValues.WhereAndHowLong.labels.countryTravel,
      })}
      error={!!error}
      hintObject={{
        message: error?.message,
      }}
      testId="travel-country"
    />
  );
};
