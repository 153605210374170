import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { WhoAndHowPaperwork } from '@entities/import-smart-components/who-and-how-paperwork';
import { useStores } from '@shared/hooks';
import { Traveler } from '@shared/types';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo, forwardRef, useCallback } from 'react';
import type { FC, Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  WhoAndHowPaperworkValues,
  WhoAndHowPaperworkOptions,
} from 'smart-components';

export const WhoAndHowPaperworkAdapter: FC<
  SmartComponentAdapterProps<WhoAndHowPaperworkValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: { initState },
          productStore: {
            ageRange,
            formState: { WhoIssuesPolicy, WhereAndHowLong, WhoAndHow },
            setTravelersData,
          },
          applicationStore: { updateFormValue },
        },
      } = useStores();

      const hasAuthorized = WhoIssuesPolicy?.hasAuthorized;

      const onDataEntered = useCallback(() => {
        sendAnalyticEvent(analyticEvents.travelerTravellerDataEntered);
      }, []);

      const handleDeleteMyself = useCallback(() => {
        updateFormValue('WhoIssuesPolicy', {
          ...WhoIssuesPolicy,
          forMe: false,
        });
      }, [WhoIssuesPolicy]);

      const handleSyncAges = useCallback(
        (travelers: Traveler[]) => {
          const updatedAges = setTravelersData(travelers);

          updateFormValue('WhoAndHow', {
            ...WhoAndHow,
            ageSelect: updatedAges,
          });
        },
        [WhoAndHow]
      );

      const options: WhoAndHowPaperworkOptions = useMemo(
        () => ({
          isMe: WhoIssuesPolicy?.forMe,
          maxObjectsNumber: initState?.maxObjectsNumber,
          risks: initState?.risks,
          ageRange,
          hasAuthorized,
          dateStart: WhereAndHowLong?.travelDates?.start,
          agesLength: WhoAndHow?.ageSelect?.length || 0,
          onDataEntered,
          onDeleteMyself: handleDeleteMyself,
          onSyncAges: handleSyncAges,
        }),
        [
          initState?.maxObjectsNumber,
          initState?.risks,
          ageRange,
          WhoAndHow?.ageSelect?.length,
          hasAuthorized,
          WhoIssuesPolicy?.forMe,
          WhereAndHowLong?.travelDates?.start,
          onDataEntered,
          handleDeleteMyself,
          handleSyncAges,
        ]
      );

      return (
        <Suspense>
          <WhoAndHowPaperwork
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

WhoAndHowPaperworkAdapter.displayName = 'WhoAndHowPaperworkAdapter';
