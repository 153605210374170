import type { FC, ReactNode } from 'react';

import { addTestAttribute } from '@shared/utils';

import { Container, Label, Value } from './label-value.styles';

interface OrderBlockItemProps {
  label: string;
  value: string | ReactNode;
  testId?: string;
}

export const LabelValue: FC<OrderBlockItemProps> = ({
  label,
  value,
  testId = 'label-value',
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Value {...addTestAttribute(`${testId}-value`)}>{value}</Value>
    </Container>
  );
};
