import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Back } from '@pulse-web-ui/icons';

import { addTestAttribute } from '@shared/utils';

import { StyledBackButton, StyledContainer } from './back-button.styles';

type BackButtonProps = {
  handleOnClickBack: () => void;
};

export const BackButton: FC<BackButtonProps> = ({ handleOnClickBack }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledBackButton
        variant="text"
        icon={<Back color="active" width={24} />}
        onClick={handleOnClickBack}
        {...addTestAttribute('form-back-button')}
      >
        {t('COMMON:labels.back')}
      </StyledBackButton>
    </StyledContainer>
  );
};
