import { WhoIssuesPolicy } from '@entities/import-smart-components/who-Issues-policy';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo, forwardRef, useCallback } from 'react';
import type { FC, Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  WhoIssuesPolicyValues,
  WhoIssuesPolicyOptions,
} from 'smart-components';

export const WhoIssuesPolicyAdapter: FC<
  SmartComponentAdapterProps<WhoIssuesPolicyValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { deviceType, phoneFromMobileApp, updateFormValue },
          authStore: { disableForm, disableProfileState },
          productStore: {
            agentLogin,
            formState: {
              WhoAndHowPaperwork,
              WhoIssuesPolicy: whoIssuesPolicyState,
            },
          },
        },
      } = useStores();

      const isMe = whoIssuesPolicyState?.forMe;

      const handleChangeDate = useCallback(
        (newBirthDate: Date) => {
          if (isMe && WhoAndHowPaperwork?.travelers) {
            const travelers = [...WhoAndHowPaperwork.travelers];
            travelers[0] = { ...travelers[0], birthday: newBirthDate };
            updateFormValue('WhoAndHowPaperwork', {
              ...WhoAndHowPaperwork,
              travelers,
            });
          }
        },
        [WhoAndHowPaperwork, isMe]
      );

      const options: WhoIssuesPolicyOptions = useMemo(
        () => ({
          deviceType,
          phoneFromMobileApp,
          agentLogin,
          disableProfileState,
          onChangeAuthorizedBirthDate: handleChangeDate,
        }),
        [
          deviceType,
          phoneFromMobileApp,
          agentLogin,
          disableProfileState,
          handleChangeDate,
        ]
      );

      return (
        <Suspense fallback={<FallbackSkeleton height={340} />}>
          <WhoIssuesPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
            disabled={disableForm}
          />
        </Suspense>
      );
    }
  )
);

WhoIssuesPolicyAdapter.displayName = 'WhoIssuesPolicyAdapter';
