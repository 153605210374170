/* eslint-disable indent */
import { Button } from '@pulse-web-ui/button';
import { media, typography, styled, css } from '@pulse-web-ui/theme';

export const StyledLongBlock = styled.div``;

export const StyledLongLabel = styled.div`
  ${typography.medium20}
  ${media.desktop} {
    ${typography.medium24}
    line-height: 32px;
  }
`;

export const StyledLongText = styled.div`
  ${typography.regular18}
  line-height: 26px;
  color: ${({ theme }) => theme?.colors?.text?.secondary};
  padding-top: 8px;
  padding-bottom: 24px;
`;

export const StyledInstallButton = styled(Button)`
  width: 100%;
  ${media.desktop} {
    width: 274px;
  }
`;

export const StyledParagraph = styled.div`
  ${typography.medium16}
  line-height: 24px;
  padding-top: 24px;
  padding-bottom: 16px;
  ${media.desktop} {
    ${typography.medium18}
    line-height: 26px;
    padding-top: 32px;
    padding-bottom: 16px;
  }
`;

export const StyledCards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 34px;
  ${media.desktop} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 14px;
  }
  grid-auto-rows: auto;
`;

export const StyledCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral['30']};
  border-radius: ${({ theme }) => `calc(${theme.common.radius} * 6)`};
  padding: 24px 16px 24px 24px;
`;

export const StyledCardImageBox = styled.div`
  width: 64px;
  height: 64px;
`;

export const StyledCardImage = styled.img`
  width: 120px;
  height: 100px;
`;

export const StyledCardText = styled.div`
  padding-top: 16px;
  ${typography.regular16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledBottomLabel = styled.div`
  padding-top: 24px;
  ${typography.medium16}
  line-height: 24px;
  ${media.desktop} {
    padding-top: 32px;
    ${typography.medium18}
    line-height: 26px;
  }
`;

export const StyledSocialButtons = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding-top: 24px;
  ${media.desktop} {
    gap: 16px;
    flex-direction: row;
  }
`;

export const StyledButtonText = styled.div`
  padding-left: 8px;
`;

export const StyledQRBox = styled.div`
  ${media.desktop} {
    [data-reach-menu-button] {
      ${typography.medium18}
      line-height: 26px;
    }
  }
`;

export const StyledButton = styled(Button)<{
  isMobileApp?: boolean;
}>`
  width: 100%;
  ${media.desktop} {
    ${({ isMobileApp }) =>
      isMobileApp &&
      css`
        width: 274px;
      `}
  }
`;
