import { SelectorCard } from '@pulse-web-ui/selector-card';
import { FC, useState } from 'react';
import { Content } from './content';
import type { RisksOptionsType } from '@shared/types';
import { RadioCardProps } from '../want-improve-policy.types';
import { Currency } from '@pulse-web-ui/range-input';
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';

export const RadioCard: FC<RadioCardProps> = ({
  value,
  currency,
  onChange,
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  const getContent = (item: RisksOptionsType) => {
    if (!item.isRadio && !item.isSlider) {
      return;
    }

    const handleChange = (val: number, id: string) => {
      if (id === value.code) {
        onChange({
          ...value,
          sum: val,
        });
      }

      return value;
    };

    if (item.checked) {
      return (
        <Content
          item={{ ...item, name: item.code }}
          onChange={handleChange}
          currency={currency as Currency}
          hasFocus={hasFocus}
        />
      );
    }
  };

  const handleClick = (id: string) => () => {
    if (value.code === id) {
      // Дефолтное значение при клике
      if (value.sum === 0) {
        value.sum = value.coverageSum[0];
      }
      onChange({
        ...value,
        checked: !value.checked,
      });
      if (!value.checked) {
        sendAnalyticEvent(analyticEvents.travelOptionAdded);
      }

      setHasFocus(true);
    }
  };

  return (
    <SelectorCard
      name={value.name}
      key={`${value.code}`}
      id={value.code}
      label={value.name}
      checked={value.checked}
      description={value.description}
      stopContentPropagation={value.checked}
      content={getContent(value)}
      onClick={handleClick(value.code)}
      mobileFullWidth
      testId="travel-improve-option"
    />
  );
};
