import { useCallback, useEffect } from 'react';

import { useRequest, useStores } from '@shared/hooks';
import {
  Endpoints,
  IFrameMessage,
  POST_MESSAGE_TYPE,
  QUERY_KEYS,
} from '@shared/constants';
import { getOrderData } from '@shared/queries';

export const useHandleAgentSubmit = () => {
  const {
    MainStore: {
      initProductStore: { initState },
      productStore: {
        agentLogin,
        formState,
        currency,
        price: { promoCode },
      },
      authStore: { accessToken },
    },
  } = useStores();

  // TODO: Use common query
  const {
    isFetching,
    error,
    res,
    refetch: refetchSubmitOrder,
  } = useRequest(
    QUERY_KEYS.submitOrder,
    'post',
    Endpoints.SUBMIT_ORDER,
    getOrderData({ formState, initState, currency, promoCode, agentLogin }),
    [],
    true,
    accessToken
  );

  useEffect(() => {
    if (!isFetching && !!res && !!agentLogin) {
      postSubmitMessageToDigitalAgent(IFrameMessage.SUBSCRIPTION_CREATED);
    }
  }, [res, isFetching]);

  useEffect(() => {
    if (error && !res && !!agentLogin) {
      const e = error?.response?.status;

      if (e === 400 && error?.response?.data?.code === 'USER_DATA_ERROR') {
        postSubmitMessageToDigitalAgent(IFrameMessage.SUBSCRIPTION_ERROR);
      }
    }
  }, [error, res]);

  const postSubmitMessageToDigitalAgent = useCallback((message: string) => {
    window.top?.postMessage(
      JSON.stringify({
        message,
        type: POST_MESSAGE_TYPE,
      }),
      window.envUrls.AGENT_URL
    );
  }, []);

  return {
    submitOrder: refetchSubmitOrder,
    isSubmitLoading: isFetching,
  };
};
