import { lazy, Suspense, useCallback } from 'react';
import {
  StyledLongBlock,
  StyledLongLabel,
  StyledLongText,
  StyledParagraph,
  StyledCards,
  StyledCard,
  StyledCardText,
  StyledBottomLabel,
  StyledSocialButtons,
  StyledButtonText,
  StyledQRBox,
  StyledButton,
  StyledCardImageBox,
} from './payment-layout.styles';
import { Button } from '@pulse-web-ui/button';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { Trans, useTranslation } from 'react-i18next';
import { SOCIAL } from '@shared/constants/social';
import { PaymentLayoutProps } from './payment-layout.types';
import { isBrowser } from 'react-device-detect';
import { domains } from '@shared/domains';
import { useIsDesktop } from '@shared/hooks';
import { isAppleSafari } from '@shared/utils/is-apple-safari';

const TelegramIcon = lazy(() => import('@shared/icons/telegram'));
const VkIcon = lazy(() => import('@shared/icons/vk'));
const DisplayQRButton = lazy(
  () => import('@pulse-web-ui/display-qr-button/lib/lazy')
);

export const PaymentLayout = ({
  policyText,
  policyLabel,
  items,
  isMobileApp,
}: PaymentLayoutProps) => {
  const { t } = useTranslation();
  const isDesk = useIsDesktop();

  const handleClick = (path: string) => {
    window.open(path);
  };

  const handleMobileAppClick = () => window.open(domains.mobileAppDeepLink);

  const handleMobileAppClickForBrowser = () => {
    const deepLink = window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK;

    isAppleSafari()
      ? (window.location.href = deepLink)
      : window.open(deepLink, '_blank');
  };

  const shareLifehack = isDesk
    ? t('COMMON:pay.shareLifehack')
    : t('COMMON:pay.shareLifehackMobile');

  const handleClickTelegram = useCallback(
    () => handleClick(SOCIAL.telegram.path),
    []
  );

  const handleClickVk = useCallback(() => handleClick(SOCIAL.vk.path), []);

  return (
    <>
      <WidgetContainer>
        <StyledLongBlock>
          <StyledLongLabel>{policyLabel}</StyledLongLabel>
          <StyledLongText>{policyText}</StyledLongText>

          {isMobileApp && (
            <StyledButton
              variant="primary"
              isMobileApp={isMobileApp}
              label={t('COMMON:pay.backToMobileApp')}
              onClick={handleMobileAppClick}
            />
          )}

          {!isMobileApp && (
            <>
              {isBrowser ? (
                <StyledQRBox>
                  <Suspense>
                    <DisplayQRButton
                      buttonLabel={t('COMMON:pay.downloadApp')}
                      dropdownSubtitle={t('COMMON:pay.pointYourCamera')}
                    />
                  </Suspense>
                </StyledQRBox>
              ) : (
                <StyledButton
                  variant="primary"
                  label={t('COMMON:pay.downloadApp')}
                  onClick={handleMobileAppClickForBrowser}
                />
              )}
              <StyledParagraph>{t('COMMON:pay.inAppYouCan')}</StyledParagraph>
              <StyledCards>
                {items.map((item) => (
                  <StyledCard>
                    <StyledCardImageBox>{item.icon}</StyledCardImageBox>
                    <StyledCardText>{item.text}</StyledCardText>
                  </StyledCard>
                ))}
              </StyledCards>
              <StyledBottomLabel>
                <Trans>{shareLifehack}</Trans>
              </StyledBottomLabel>
              <StyledSocialButtons>
                <Button onClick={handleClickTelegram}>
                  <Suspense>
                    <TelegramIcon />
                  </Suspense>
                  <StyledButtonText>
                    {t('COMMON:pay.telegram')}
                  </StyledButtonText>
                </Button>
                <Button onClick={handleClickVk}>
                  <Suspense>
                    <VkIcon />
                  </Suspense>
                  <StyledButtonText>
                    {t('COMMON:pay.vkontakte')}
                  </StyledButtonText>
                </Button>
              </StyledSocialButtons>
            </>
          )}
        </StyledLongBlock>
      </WidgetContainer>
    </>
  );
};
