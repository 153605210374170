import { useStores } from '@shared/hooks';
import { Suspense, lazy, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  useDisabledSubmit,
  useScrollToElementWithError,
  useTravelStartEvent,
} from '../hooks';
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { FormBuilderEventBus, FormBuilderAction } from '../utils';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import type { SmartComponent } from '@shared/types';
import type { formValue } from '../utils/get-default-values';
import { FormBuilderStepItem } from './form-builder-step-item';
import { useSynchronizeStore } from '../hooks/use-synchronize-store';
import type { TBusCallback } from '@shared/event-bus/event-bus';
import { FormBuilderModalWrapper } from '../form-builder.style';
import { Spinner } from '@pulse-web-ui/spinner';

const PolicyBanned = lazy(() => import('../../../pages/policy-banned'));

type Props = {
  config: SmartComponent<any>[];
  defaultValues: formValue;
};

type FormHandlerErrorParams = { fieldName: string; err: string };
type FormHandlerParams = { fieldName: string; value: any };
type FormHandlerObject = (params: FormHandlerParams) => void;
type FormHandler = (fieldName: string, value: any) => void;
type FormErrorHandler = (params: FormHandlerErrorParams) => void;

export const FormBuilderStep: FC<Props> = observer(
  ({ config, defaultValues }) => {
    const {
      MainStore: {
        applicationStore: {
          currentStep,
          activeStep,
          wantNextStep,
          setWantNextStep,
          setCurrentStep,
          isResetFormState,
          setDisabledSubmit,
          setIsResetFormState,
          flowConfig: { otpStep },
        },
        productStore: {
          setFormState,
          formState: storeFormState,
          isPolicyBanned,
        },
        authStore: { setDisableForm, handleAuth, useOtp },
        initProductStore: { setFormBuilderIsMounted },
      },
    } = useStores();

    const {
      control,
      reset,
      watch,
      setValue,
      getValues,
      setError,
      clearErrors,
      handleSubmit,
      formState: {
        isDirty,
        isSubmitting,
        isSubmitSuccessful,
        errors,
        submitCount,
      },
    } = useForm({ values: defaultValues });

    const elementRefs = useScrollToElementWithError(submitCount, errors);

    useSynchronizeStore(watch, setFormState, clearErrors);

    useTravelStartEvent(isDirty);

    useEffect(() => {
      if (isResetFormState) {
        reset(storeFormState);
        setIsResetFormState(false);
      }
    }, [isResetFormState]);

    useEffect(() => {
      setCurrentStep(activeStep);
    }, [activeStep]);

    useEffect(() => {
      setFormBuilderIsMounted();
    }, []);

    const updateFormHandlerObject: FormHandlerObject = useCallback(
      ({ fieldName, value }) => {
        updateFormHandler(fieldName, value);
      },
      []
    );

    const updateFormHandler: FormHandler = useCallback((fieldName, value) => {
      getValues();
      setValue(fieldName, value);
    }, []);

    const updateFormErrorHandler: FormErrorHandler = useCallback(
      ({ fieldName, err }) => {
        setError(fieldName, { message: err });
        setDisableForm(false);
      },
      []
    );

    const handleAuthUser: TBusCallback<{ phone: string }> = useCallback(
      ({ phone }) => {
        if (currentStep === otpStep) {
          const client = getValues('WhoIssuesPolicy.client');

          const event = client
            ? analyticEvents.travelerSignupStart
            : analyticEvents.travelRegistrationStart;
          sendAnalyticEvent(event);
        }

        handleAuth(phone);
      },
      [handleAuth, currentStep]
    );

    useEffect(() => {
      const unsubscribe = FormBuilderEventBus.subscribe(
        FormBuilderAction.SUBMIT_FORM,
        handleSubmit(() => {})
      );

      return unsubscribe;
    }, [handleSubmit]);

    useEffect(() => {
      const unsubscribe = FormBuilderEventBus.subscribe(
        FormBuilderAction.UPDATE_FORM,
        updateFormHandlerObject
      );

      return unsubscribe;
    }, [updateFormHandlerObject]);

    useEffect(() => {
      const unsubscribe = FormBuilderEventBus.subscribe<FormHandlerErrorParams>(
        FormBuilderAction.UPDATE_FORM_ERRORS,
        updateFormErrorHandler
      );

      return unsubscribe;
    }, [updateFormErrorHandler]);

    useEffect(() => {
      const unsubscribe = FormBuilderEventBus.subscribe<{ phone: string }>(
        FormBuilderAction.AUTH_USER,
        handleAuthUser
      );

      return unsubscribe;
    }, [handleAuthUser]);

    const isShowOtp = useOtp && currentStep === otpStep;
    const disabledSubmit = useDisabledSubmit({
      errors,
      submitCount,
      wantNextStep,
      isShowOtp,
    });

    useEffect(() => {
      setDisabledSubmit(disabledSubmit);
    }, [disabledSubmit]);

    useEffect(() => {
      if (isSubmitSuccessful && !isSubmitting) {
        setWantNextStep(true);
      }
    }, [isSubmitSuccessful, isSubmitting]);

    const setRefHandler = useCallback(
      (e: HTMLDivElement, name: string) => (elementRefs.current[name] = e),
      []
    );

    return isPolicyBanned ? (
      <FormBuilderModalWrapper>
        <Suspense fallback={<Spinner />}>
          <PolicyBanned updateFormValue={updateFormHandler} />
        </Suspense>
      </FormBuilderModalWrapper>
    ) : (
      config
        .filter((item) => item?.step === activeStep)
        .map((component) => (
          <FormBuilderStepItem
            setRef={setRefHandler}
            activeStep={activeStep}
            key={`${activeStep}${component.props.name}`}
            component={component}
            isSubmitting={isSubmitting}
            control={control}
          />
        ))
    );
  }
);

FormBuilderStep.displayName = 'FormBuilderStep';
